import moment from "moment";
import { FlexContainer } from "promenade-react-js";
import * as React from "react";
import { Button, Loader } from "semantic-ui-react";

import Body from "../../components/typography/Body";
import LoginManager from "../../managers/LoginManager";
import PatientManager from "../../managers/PatientManager";
import ScreenPaths, { ADDITIONAL_TREATMENT } from "../../managers/ScreenManager";
import Patient, { DEFAULT_TREATMENT_EXPIRATION_DATE, ITreatment } from "../../models/Patient";
import {
    ElementSandglass,
    ElementTreatments1,
    ElementTreatments10,
    ElementTreatments11,
    ElementTreatments12,
    ElementTreatments13,
    ElementTreatments14,
    ElementTreatments15,
    ElementTreatments16,
    ElementTreatments17,
    ElementTreatments18,
    ElementTreatments2,
    ElementTreatments3,
    ElementTreatments4,
    ElementTreatments5,
    ElementTreatments6,
    ElementTreatments7,
    ElementTreatments8,
    ElementTreatments9,
} from "../../resources/images";
import Strings from "../../resources/strings";
import { titleCase } from "../../utils/String";
import HCPBaseScreen from "../base/HCPBaseScreen";
import "./styles/PatientDetailsScreen.css";

interface IPatientDetailsScreenState {
    loading: boolean;
    patient: Patient|null;
}

export default class PatientDetailsScreen extends HCPBaseScreen<{}, IPatientDetailsScreenState> {
    public static path: string = "/patient/:patientID";

    public constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            patient: this.navParams.patient || null,
        };
    }

    protected resetScreen = async (): Promise<void> => {
        if (LoginManager.isLoggedIn()) {
            try {
                if (this.state.patient === null) {
                    await this.getPatient();
                }
            } catch (e) {
                this.resetNavigationToHome();
                console.warn(e);
            } finally {
                this.setState({loading: false});
            }
        }

    };

    private getPatient = async (): Promise<void> => {
        const patient =  await PatientManager.getPatient(this.urlParams.patientID);
        if (patient === undefined) {
            throw Error("Invalid Patient ID");
        }
        this.setState({patient});
    };

    private handleBuyTreatmentPress = async (): Promise<void> => {
        if (this.state.patient === null) {
            return;
        }
        this.pushScreen(
            ScreenPaths.PAYMENT_DETAILS,
            {patient: this.state.patient},
            {treatment: ADDITIONAL_TREATMENT},
        );
    };

    private renderTreatmentExpiration(): JSX.Element|null {
        if (this.state.patient === null) {
            return null;
        }

        const treatmentPackage = this.state.patient.getLatestTreatmentPackage();

        // If there are no treatment packages at all or the current treatment package expiration date is the default
        // (unset), then don't display any expiration
        if (
            treatmentPackage === null
            || moment(treatmentPackage.endDateTime).isSame(DEFAULT_TREATMENT_EXPIRATION_DATE)
        ) {
            return null;
        }

        // If the current treatment package expired, display the expired message
        if (moment(treatmentPackage.endDateTime).isBefore(moment())) {
            return (
                <FlexContainer className="patient-details-screen__patient-details-item">
                    <Body size={2} color="red">{Strings.t("promiseExpiredOn")}</Body>
                    <Body size={2} bold className="patient-details-screen__patient-details-body">
                        {moment(treatmentPackage.endDateTime).format("MMMM Do, YYYY")}
                    </Body>
                </FlexContainer>
            );
        }

        // Otherwise, display the expiration date of the current treatment package
        return (
            <FlexContainer className="patient-details-screen__patient-details-item">
                <Body size={2}>{Strings.t("promiseExpiresOn")}</Body>
                <Body size={2} bold className="patient-details-screen__patient-details-body">
                    {moment(treatmentPackage.endDateTime).format("MMMM Do, YYYY")}
                </Body>
            </FlexContainer>
        );
    }

    private renderTreatmentPackageCount(): JSX.Element|null {
        if (this.state.patient === null) {
            return null;
        }
        return (
            <FlexContainer className="patient-details-screen__patient-details-item">
                <Body size={2}>{Strings.t("treatmentPackageNumber")}</Body>
                <Body size={2} bold className="patient-details-screen__patient-details-body">
                    {this.state.patient.packages.length.toString()}
                </Body>
            </FlexContainer>
        );
    }

    private renderTreatmentsCompleted(): JSX.Element|null {
        if (this.state.patient === null) {
            return null;
        }
        return (
            <FlexContainer className="patient-details-screen__patient-details-item">
                <Body size={2}>{Strings.t("treatmentsCompleted")}</Body>
                <Body size={2} bold className="patient-details-screen__patient-details-body">
                    {this.state.patient.treatmentsCompleted.toString()}
                </Body>
            </FlexContainer>
        );
    }

    private renderDateLastTreatment(): JSX.Element|null {
        if (this.state.patient === null || this.state.patient.treatmentsCompleted == 0) {
            return null;
        }
        return (
            <FlexContainer className="patient-details-screen__patient-details-item">
                <Body size={2}>{Strings.t("dateLastTreatment")}</Body>
                <Body size={2} bold className="patient-details-screen__patient-details-body">
                    {this.state.patient.treatments[this.state.patient.treatments.length - 1].endDateTime.substr(0,10)}
                </Body>
            </FlexContainer>
        );
    }

    private renderBuyTreatmentButton(): JSX.Element|null {
        if (this.state.patient === null) {
            return null;
        }

        // Allow purchasing additional treatments at any time.

        return (
            <Button
                className="patient-details-screen__treatment-button"
                color="black"
                content={Strings.t("buyAdditionalTreatment")}
                onClick={this.handleBuyTreatmentPress}
                size="big"
            />
        );
    }

    private renderPatientInformation(): JSX.Element|null {
        if (this.state.patient === null) {
            return null;
        }

        let patientAgeText = null;
        if (this.state.patient.age !== -1) {
            patientAgeText = (
                <Body size={2} className="patient-details-screen__patient-age-body">
                    {this.state.patient.age + " " + Strings.t("yearsOldShortForm")}
                </Body>
            );
        }
        return (
            <FlexContainer
                column
                alignment="left"
                className="patient-details-screen__patient-details-container"
                justification="space-between"
            >
                <FlexContainer className="patient-details-screen__patient-details-item" alignment="baseline">
                    <Body size={5}>
                        {Strings.t("patient") + " " + titleCase(this.state.patient.lastName ? this.state.patient.lastName: this.state.patient.upi)}
                    </Body>
                    {patientAgeText}
                </FlexContainer>
                <FlexContainer className="patient-details-screen__patient-details-item">
                    <Body size={2}>
                        {Strings.t("upi")}
                    </Body>
                    <Body size={2} bold className="patient-details-screen__patient-details-body">
                        {titleCase(this.state.patient.upi)}
                    </Body>
                </FlexContainer>
                <FlexContainer className="patient-details-screen__patient-details-item">
                    <Body size={2}>
                        {Strings.t("id")}
                    </Body>
                    <Body size={2} bold className="patient-details-screen__patient-details-body">
                        {titleCase(this.state.patient.id)}
                    </Body>
                </FlexContainer>
                {this.renderTreatmentExpiration()}
                {this.renderTreatmentPackageCount()}
                {this.renderTreatmentsCompleted()}
                {this.renderDateLastTreatment()}
                {this.renderBuyTreatmentButton()}
            </FlexContainer>
        );
    }

    private getTreatmentGraphic(treatmentsCompleted: number): JSX.Element|null {
        let src;
        switch (treatmentsCompleted) {
            case 1:
                src = ElementTreatments1;
                break;
            case 2:
                src = ElementTreatments2;
                break;
            case 3:
                src = ElementTreatments3;
                break;
            case 4:
                src = ElementTreatments4;
                break;
            case 5:
                src = ElementTreatments5;
                break;
            case 6:
                src = ElementTreatments6;
                break;
            case 7:
                src = ElementTreatments7;
                break;
            case 8:
                src = ElementTreatments8;
                break;
            case 9:
                src = ElementTreatments9;
                break;
            case 10:
                src = ElementTreatments10;
                break;
            case 11:
                src = ElementTreatments11;
                break;
            case 12:
                src = ElementTreatments12;
                break;
            case 13:
                src = ElementTreatments13;
                break;
            case 14:
                src = ElementTreatments14;
                break;
            case 15:
                src = ElementTreatments15;
                break;
            case 16:
                src = ElementTreatments16;
                break;
            case 17:
                src = ElementTreatments17;
                break;
            case 18:
                src = ElementTreatments18;
                break;
            default:
                return null;
        }
        return (
            <img src={src} alt={String(treatmentsCompleted)} />
        );
    }

    private renderTreatmentGraphic(): JSX.Element|null {
        if (this.state.patient === null) {
            return null;
        }

        const treatmentPackage = this.state.patient.getLatestTreatmentPackage();

        // If the current treatment package expiration date is the default (unset), then don't display anything
        // (This occurs when patient hasn't completed any treatments in the package yet -- the device only sets the
        // expiration date after the 1st treatment is over)
        if (treatmentPackage === null) {
            return null;
        }
        // By default, display the total number of treatments completed (in current package)
        const treatmentsCompletedInCurrentPackage = this.state.patient.treatments.filter((treatment: ITreatment) => {
            return treatment.packageNumber === treatmentPackage.packageNumber;
        });
        let graphic = this.getTreatmentGraphic(treatmentsCompletedInCurrentPackage.length);
        let graphicSubtitle = Strings.t("treatmentsHaveBeenCompleted");

        if (treatmentsCompletedInCurrentPackage.length === 0) {
            graphicSubtitle = Strings.t("firstTreatmentNotCompleted");
        } else if (treatmentPackage.isCompleted) {
            // If the most recent treatment package is complete, display the completion message
            graphicSubtitle = Strings.t("standardTreatmentCompleted");
        } else if (
            // If the current treatment package expired, display the expired message
            this.state.patient.treatmentsCompleted >= this.state.patient.maxTreatments
            || (
                moment(treatmentPackage.endDateTime).isBefore(moment())
                && !moment(treatmentPackage.endDateTime).isSame(DEFAULT_TREATMENT_EXPIRATION_DATE)
            )
        ) {
            graphic = <img src={ElementSandglass} alt={Strings.t("hourglass")} />;
            graphicSubtitle = Strings.t("treatmentExpired");
        }

        return (
            <FlexContainer
                column
                alignment="center"
                className="patient-details-screen__treatment-information-container"
            >
                {graphic}
                <Body size={2} className="patient-details-screen__treatment-information">
                    {graphicSubtitle}
                </Body>
            </FlexContainer>
        );
    }

    private renderContentContainer(): JSX.Element {
        return (
            <FlexContainer className="patient-details-screen__content-container" justification="space-between">
                {this.renderPatientInformation()}
                {this.renderTreatmentGraphic()}
            </FlexContainer>
        );
    }

    protected renderScreen(): JSX.Element {
        return (
            <FlexContainer column alignment="center" className="patient-details-screen__screen-container">
                <Loader active={this.state.loading} />
                {this.renderBackButton()}
                {this.renderContentContainer()}
            </FlexContainer>
        );
    }
}
