import { PromenadeRouter } from "promenade-react-js";
import * as React from "react";
import "semantic-ui-css/semantic.min.css";

import ScreenPaths, { registerScreens } from "./managers/ScreenManager";

PromenadeRouter.ScreenPaths = ScreenPaths;
const AppScreenManager = registerScreens();

class App extends React.Component {
    public render(): JSX.Element {
        const MainApp = AppScreenManager.ApplicationNavigator;
        return <MainApp />;
    }
}

export default App;
