import { FlexContainer } from "promenade-react-js";
import React from "react";
import { Button, Icon } from "semantic-ui-react";

import Body from "../../components/typography/Body";
import ScreenPaths from "../../managers/ScreenManager";
import Strings from "../../resources/strings";
import HCPBaseScreen from "../base/HCPBaseScreen";
import "./styles/PaymentSuccessScreen.css";

export default class PaymentSuccessScreen extends HCPBaseScreen<{}, {}> {
    public static path: string  = "/payment/:treatment/success";

    protected resetScreen(): void {
        if (this.navParams.price === undefined) {
            this.resetNavigationToHome();
        }
    }

    private handleBackHomeButtonPress = (): void => {
        this.pushScreen(ScreenPaths.HOME, {paymentType: this.urlParams.treatment});
    };

    private renderPaymentSuccessText = (): JSX.Element => {
        const displayPrice = this.navParams.price || "";
        return (
            <FlexContainer alignment="center" className="payment-success-screen__body-item">
                <Icon name="check circle outline" size="huge" className="payment-success-screen__icon" />
                <Body size={4}>{Strings.t("paymentAmountSuccess").replace("_", displayPrice)}</Body>
            </FlexContainer>
        );
    };

    private renderButton = (): JSX.Element => {
        return (
            <Button
                color="black"
                content={Strings.t("backToHome")}
                size="huge"
                onClick={this.handleBackHomeButtonPress}
                className="payment-success-screen__button payment-success-screen__body-item"
            />
        );
    };

    protected renderScreen(): JSX.Element {
        return (
            <FlexContainer
                column
                justification="center"
                alignment="center"
                className="payment-success-screen__container"
            >
                <Body size={5} className="payment-success-screen__body-item">
                    {Strings.t("paymentSuccess")}
                </Body>
                {this.renderPaymentSuccessText()}
                {this.renderButton()}
            </FlexContainer>
        );
    }
}
