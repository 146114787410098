import { FlexContainer, PromenadeRouter } from "promenade-react-js";
import * as React from "react";
import { Button, Icon } from "semantic-ui-react";
import Body from "../../components/typography/Body";
import ScreenPaths from "../../managers/ScreenManager";
import Strings from "../../resources/strings";
import "./styles/PageNotFoundScreen.css";

export default class PageNotFoundScreen extends PromenadeRouter<{}> {

    protected handleBackPress = (): void => {
        this.pushScreen(ScreenPaths.LOGIN);
    };

    public render(): JSX.Element {
        const errorCode404 = "404";
        return (
            <FlexContainer column alignment="center" className="page-not-found-screen__screen-container">
                <div className="page-not-found__back-button-container">
                    <Button size="huge" labelPosition="left" onClick={this.handleBackPress}>
                        <Icon name="chevron left" />
                        {Strings.t("login")}
                    </Button>
                </div>

                <FlexContainer className="page-not-found-screen__error-message-container" column alignment="center">
                    <Body size={8}>{errorCode404}</Body>
                    <Body size={6}>{Strings.t("pageNotFound")}</Body>
                </FlexContainer>
            </FlexContainer>
        );
    }
}
