import { FlexContainer, PromenadeRouter } from "promenade-react-js";
import * as React from "react";
import { Button, Input, Loader } from "semantic-ui-react";

import AlertStack from "../../components/AlertStack";
import Body from "../../components/typography/Body";
import Version from "../../defs/Version";
import LoginManager from "../../managers/LoginManager";
import ScreenPaths from "../../managers/ScreenManager";
import SessionManager from "../../managers/SessionManager";
import ForgotPasswordModal from "../../modals/ForgotPasswordModal";
import SolaTherapyLogo from "../../resources/images/SolaTherapyLogo.png";
import Strings from "../../resources/strings";
import "./styles/LoginScreen.css";

interface ILoginScreenState {
    username: string;
    password: string;
    loading: boolean;
    highlightErrors: boolean;
}

export default class LoginScreen extends PromenadeRouter<{}, ILoginScreenState> {
    public static path: string = "/";
    public static pathAlias: string = "/login";

    private alertStack: React.RefObject<AlertStack> = React.createRef<AlertStack>();
    private forgotPasswordModal: React.RefObject<ForgotPasswordModal> = React.createRef<ForgotPasswordModal>();

    public constructor(props: any) {
        super(props);
        this.state = {
            username: "",
            password: "",
            loading: false,
            highlightErrors: false,
        };

        // Register handler for when the session expires
        if (SessionManager.expirationHandlers.length === 0) {
            SessionManager.expirationHandlers.push(async (): Promise<void> => {
                await LoginManager.logout();
                this.resetNavigation(ScreenPaths.DEFAULT, {sessionTimeout: true});
            });
        }
    }

    public componentDidMount(): void {
        if (this.navParams.sessionTimeout) {
            const alertStack = this.alertStack.current;
            if (alertStack) {
                alertStack.push(Strings.t("sessionTimedOut"), Strings.t("youHaveBeenLoggedOut"));
            }
        }
    }

    protected updateUsername = (value: string): void => this.setState({username: value});
    protected updatePassword = (value: string): void => this.setState({password: value});

    protected login = async (): Promise<void> => {
        this.setState({loading: true});
        if (this.state.username === "" || this.state.password === "") {
            const alertStack = this.alertStack.current;
            if (alertStack) {
                alertStack.push(Strings.t("loginError"), Strings.t("missingUsernamePassword"));
            }
            this.setState({highlightErrors: true, loading: false});
            return;
        }

        try {
            await LoginManager.login(this.state.username, this.state.password);
            this.pushScreen(ScreenPaths.HOME);
        } catch (e) {
            this.setState({loading: false, highlightErrors: true});
            this.handleLoginError(e);
        }
    };

    protected forgotPassword = (): void => {
        const forgotPasswordModal = this.forgotPasswordModal.current;
        if (forgotPasswordModal) {
            forgotPasswordModal.show();
        }
    };

    protected register = (): void => {
        this.pushScreen(ScreenPaths.REGISTER_PROVIDER_SCREEN);
    };

    private handleLoginError(e: Error): void {
        const alertStack = this.alertStack.current;
        switch (e.message || e) {
            case "Wrong username or password.":
            case "User not in project":
            case "User not in a user group":
                if (alertStack) {
                    alertStack.push(Strings.t("loginError"), Strings.t("wrongUsernamePassword"));
                }
                return;
            case "Failed to fetch":
                if (alertStack) {
                    alertStack.push(Strings.t("loginError"), Strings.t("networkRequestFailed"));
                }
                return;
            default:
                console.warn(e);
                return;
        }
    }

    private handleInputKeyPressed = (event: KeyboardEvent): void => {
        if (event.key === "Enter") {
            this.login();
        }
    };

    private renderLogo(): JSX.Element {
        return (
            <FlexContainer justification="center" alignment="center">
                <img src={SolaTherapyLogo} className="login-screen__logo" />
            </FlexContainer>
        );
    }

    private renderVersion(): JSX.Element {
        return (
            <Body size={3} className="login-screen__version">
                {Strings.t("version") + " " + Version.versionNumber}
            </Body>
        );
    }

    private renderLoginFields(): JSX.Element {
        return (
            <FlexContainer column justification="space-between">
                <Body className="login-screen__label">{Strings.t("username")}</Body>
                <Input
                    className="login-screen__form-fields"
                    disabled={this.state.loading}
                    error={this.state.highlightErrors && this.state.username === ""}
                    onChange={(event: any, {value}: {value: string}): void => this.updateUsername(value)}
                    onKeyPress={this.handleInputKeyPressed}
                    size="big"
                    value={this.state.username}
                />
                <Body className="login-screen__label">{Strings.t("password")}</Body>
                <Input
                    className="login-screen__form-fields"
                    disabled={this.state.loading}
                    error={this.state.highlightErrors && this.state.password === ""}
                    onChange={(event: any, {value}: {value: string}): void => this.updatePassword(value)}
                    onKeyPress={this.handleInputKeyPressed}
                    size="big"
                    type="password"
                    value={this.state.password}
                />
                <Button
                    className="login-screen__button-field"
                    color="black"
                    content={Strings.t("login")}
                    onClick={this.login}
                    size="big"
                />
            </FlexContainer>
        );
    }

    private renderForgotPassword(): JSX.Element {
        return (
            <FlexContainer justification="center" className="login-screen__link-text">
                <Body size={2} onClick={this.forgotPassword}>{Strings.t("forgotPassword")}</Body>
            </FlexContainer>
        );
    }

    private renderRegistration(): JSX.Element {
        return (
            <FlexContainer justification="center" className="login-screen__link-text">
                <Body size={2} onClick={this.register}>{Strings.t("registerNewProvider")}</Body>
            </FlexContainer>
        );
    }

    public render(): JSX.Element {
        return (
            <FlexContainer justification="center" alignment="center" className="login-screen__screen-container">
                <AlertStack ref={this.alertStack} />
                <ForgotPasswordModal ref={this.forgotPasswordModal} />
                <Loader active={this.state.loading} />
                <FlexContainer column className="login-screen__form-container">
                    {this.renderLogo()}
                    {this.renderVersion()}
                    {this.renderLoginFields()}
                    {this.renderForgotPassword()}
                    {this.renderRegistration()}
                </FlexContainer>
            </FlexContainer>
        );
    }
}
