import { Debouncer } from "promenade-js";
import { FlexContainer } from "promenade-react-js";
import React from "react";
import { Button, Checkbox, Header, Icon, Input, Item, Loader } from "semantic-ui-react";

import AlertStack from "../../components/AlertStack";
import Body from "../../components/typography/Body";
import PatientManager from "../../managers/PatientManager";
import PaymentManager from "../../managers/PaymentManager";
import ScreenPaths, { ADDITIONAL_TREATMENT, NEW_TREATMENT } from "../../managers/ScreenManager";
import Patient from "../../models/Patient";
import Strings from "../../resources/strings";
import HCPBaseScreen from "../base/HCPBaseScreen";
import { IAddPatientScreenNavParams } from "./AddPatientScreen";
import "./styles/PaymentDetailsScreen.css";

interface IPaymentDetailsScreenState {
    highlightErrors: boolean;
    loading: boolean;
    patientConsent: boolean;
    patientPrice: string;
    uroshapePrice: string;
    uroshapePriceIsStale: boolean;
}

const REQUEST_DEBOUNCE_MS: number = 1000;
const MAX_PRICE_LENGTH: number = 9;

export default class PaymentDetailsScreen extends HCPBaseScreen<{}, IPaymentDetailsScreenState> {
    public static path: string  = "/payment/:treatment";
    private alertStack: React.RefObject<AlertStack> = React.createRef<AlertStack>();
    private requestDebouncer: Debouncer = new Debouncer(REQUEST_DEBOUNCE_MS);
    private patient: Patient|null = this.navParams.patient || null;

    public constructor(props: any) {
        super (props);

        this.state = {
            highlightErrors: false,
            loading: false,
            patientConsent: false,
            patientPrice: "",
            uroshapePrice: "",
            uroshapePriceIsStale: false,
        };
    }

    public componentDidMount(): void {
        super.componentDidMount();
        if (this.patient === null) {
            this.resetNavigationToHome();
        }
    }

    private updatePatientPrice = (value: string): void => {
        this.setState({
            patientPrice: value.substring(0, MAX_PRICE_LENGTH),
            uroshapePriceIsStale: true,
        });
        this.requestDebouncer.debounce(() => this.queryUroshapePrice(value));
    };

    private queryUroshapePrice = async (patientPrice: string): Promise<void> => {
        if (this.patient === null) {
            return;
        }

        this.setState({loading: true});
        try {
            const uroshapePrice = await PaymentManager.getTemporaryQuote(patientPrice, this.patient.clientId);

            this.setState({
                uroshapePrice,
                loading: false,
                uroshapePriceIsStale: false,
            });
        } catch (e) {
            console.warn(e);
        } finally {
            this.setState({loading: false, uroshapePriceIsStale: false});
        }
    };

    private updatePatientConsent = (): void => this.setState({patientConsent: !this.state.patientConsent});

    private async goToFailureScreen(): Promise<void> {
        const paymentUrl = await PaymentManager.getPaymentPortalUrl();
        this.pushScreen(
            ScreenPaths.PAYMENT_FAILURE,
            {price: this.state.uroshapePrice, paymentUrl},
            {treatment: this.urlParams.treatment},
        );
    }

    private async handlePaymentFailure(): Promise<void> {
        try {
            // First try to get the payment portal URL to go to failure screen
            await this.goToFailureScreen();
        } catch (e) {
            // If we fail to even get the payment portal URL, display an error alert
            console.warn(e);
            let message = Strings.t("unknownPaymentError");
            if (e.message === "Failed to fetch") {
                message = Strings.t("networkRequestFailed");
            }

            const alertStack = this.alertStack.current;
            if (alertStack) {
                alertStack.push(Strings.t("paymentError"), message);
            }
        }
    }

    private handlePayPress = async (): Promise<void> => {
        if (this.patient === null) {
            return;
        }

        const patient = Patient.FromPatient(this.patient);

        // Validate input
        if (this.state.uroshapePrice === "") {
            this.setState({highlightErrors: true});
            return;
        }

        if (this.urlParams.treatment === ADDITIONAL_TREATMENT) {
            if (!this.state.patientConsent) {
                this.setState({highlightErrors: true});
                return;
            }
            patient.consent = this.state.patientConsent;
        }

        // Add patient with treatment package and sales order
        try {
            this.setState({loading: true});
            await PaymentManager.addSalesOrder(
                this.state.patientPrice,
                this.patient.clientId,
            );

            if (this.urlParams.treatment === NEW_TREATMENT) {
                await PatientManager.addPatient(patient);
            }

            patient.addTreatmentPackage();
            await PatientManager.storePatients([patient]);

        } catch (e) {
            console.warn(e);
            await this.handlePaymentFailure();
            return;
        } finally {
            this.setState({loading: false});
        }

        this.pushScreen(
            ScreenPaths.PAYMENT_SUCCESS,
            {price: this.state.uroshapePrice},
            {treatment: this.urlParams.treatment},
        );
    };

    private handleOpenPaymentPortalPress = async (): Promise<void> => {
        this.setState({loading: true});
        try {
            const paymentUrl = await PaymentManager.getPaymentPortalUrl();
            window.open(paymentUrl);
        } catch (e) {
            console.warn(e);
        } finally {
            this.setState({loading: false});
        }
    };

    protected handleLogoPressed = (): void => {
        const alertStack = this.alertStack.current;
        if (alertStack) {
            const alertStackButtons = [
                {content: Strings.t("returnToHomeScreen"), onClick: (): void => this.resetNavigationToHome()},
                {content: Strings.t("stayOnCurrentPage")},
            ];
            alertStack.push(
                Strings.t("areYouSureReturnHome"),
                Strings.t("progressWillBeLost"),
                true,
                alertStackButtons,
            );
        }
    };

    protected handleBackPress = (): void => {
        if (this.urlParams.treatment === NEW_TREATMENT) {
            this.pushScreen(ScreenPaths.ADD_PATIENT, {patient: this.patient} as IAddPatientScreenNavParams);
        } else {
            this.popScreen();
        }
    };

    private renderFormHeader(): JSX.Element {
        return (
            <FlexContainer justification="center" className="payment-details-screen__form-header">
                <Header as="h1">{Strings.t("paymentDetails")}</Header>
            </FlexContainer>
        );
    }

    private renderForm(): JSX.Element {
        let uroshapePrice = Strings.t("calculating");
        if (!this.state.uroshapePriceIsStale) {
            uroshapePrice = Strings.t("usDollars") + this.state.uroshapePrice;
        }

        return (
            <FlexContainer column className="payment-details-screen__form-box">
                <Body size={2}>{Strings.t("priceToPatient")}</Body>
                <Input
                    className="payment-details-screen__form-line-item"
                    disabled={this.state.loading}
                    error={this.state.highlightErrors && this.state.uroshapePrice === ""}
                    onChange={(event: any, {value}: {value: string}): void => this.updatePatientPrice(value)}
                    size="big"
                    value={this.state.patientPrice}
                    type="number"
                />

                <FlexContainer
                    className="payment-details-screen__price-to-pay-container payment-details-screen__form-line-item"
                    justification="space-between"
                >
                    <Body size={3}>{Strings.t("amountChargedToCard")}</Body>
                    <Body size={3}>{uroshapePrice}</Body>
                </FlexContainer>

                <FlexContainer column className="payment-details-screen__form-line-item">
                    <Body size={1}>{Strings.t("addressLine")}</Body>
                </FlexContainer>

                <Body size={1} bold className="payment-details-screen__form-line-item">
                    {Strings.t("refundNotice")}
                </Body>

                <Button
                    className="payment-details-screen__payment-button"
                    color="black"
                    disabled={this.state.uroshapePriceIsStale || this.state.loading}
                    content={Strings.t("pay")}
                    onClick={this.handlePayPress}
                    size="big"
                />

                <FlexContainer alignment="center" className="payment-details-screen__secure-checkout">
                    <Icon name="lock" size="small" color="grey" />
                    <Body size={1}>
                        {Strings.t("secureCheckout")}
                    </Body>
                </FlexContainer>
            </FlexContainer>
        );
    }

    private renderPatientConsent(): JSX.Element {
        if (this.urlParams.treatment === ADDITIONAL_TREATMENT) {
            const textColor = this.state.highlightErrors && !this.state.patientConsent
                ? "payment-details-screen__patient-consent-string--color-error"
                : "payment-details-screen__patient-consent-string--color-normal";
            return (
                <FlexContainer alignment="center" className="payment-details-screen__patient-consent-container">
                    <Checkbox
                        checked={this.state.patientConsent}
                        className="payment-details-screen__patient-consent-checkbox"
                        onChange={this.updatePatientConsent}
                    />
                    <Item.Group link className="payment-details-screen__patient-consent-item">
                        <Item onClick={this.updatePatientConsent}>
                            <Body size={2} className={textColor}>{Strings.t("patientConsentConfirmation")}</Body>
                        </Item>
                    </Item.Group>
                </FlexContainer>
            );
        }

        return (
            <FlexContainer alignment="center" className="payment-details-screen__patient-consent-container">
                <Icon name="check" size="big" />
                <Body size={2} className="payment-details-screen__patient-consent-string">
                    {Strings.t("patientConsentComplete")}
                </Body>
            </FlexContainer>
        );
    }

    private renderPaymentPortalLink(): JSX.Element {
        return (
            <Body size={2} onClick={this.handleOpenPaymentPortalPress} className="payment-details-screen__payment-link">
                {Strings.t("openPaymentPortal")}
            </Body>
        );
    }

    protected renderScreen(): JSX.Element {
        return (
            <FlexContainer column alignment="center" className="payment-details-screen__screen-container">
                <AlertStack ref={this.alertStack} />
                <Loader active={this.state.loading} />
                {this.renderBackButton()}
                <FlexContainer column className="payment-details-screen__form-container">
                    {this.renderFormHeader()}
                    {this.renderForm()}
                    {this.renderPatientConsent()}
                    {this.renderPaymentPortalLink()}
                </FlexContainer>
            </FlexContainer>
        );
    }
}
