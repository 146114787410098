/* tslint:disable:max-line-length */
/* tslint:enable:object-literal-sort-keys*/

// Keep this alphabetized
export default {
    accountId: "Account ID",
    additionalTreatmentPaid: "Additional treatment has been successfully paid!",
    addNewPatient: "Add New Patient",
    addPatientError: "Add Patient Error",
    addressLine: "Melbourne, Florida 32901",
    age: "Age",
    allPatients: "All patients",
    amountChargedToCard: "Amount charged to credit card on file:",
    areYouSureReturnHome: "Are you sure you want to go back to the home screen?",
    back: "Back",
    backToHome: "Back to Home",
    backToLogin: "Back to Log In",
    backToLoginPrompt: "Press OK to go back to the login screen",
    buyAdditionalTreatment: "Buy additional treatment series",
    buyNewTreatment: "Buy new Treatment series",
    calculating: "Calculating...",
    clientIdInUse: "Client ID is already in use.",
    contactEmail: "customer.service@solatherapy.com",
    contactPhoneNumber: "844.557.7632",
    copyright: "2019 SoLaTherapy",
    credentialsFromSolaTherapy: "Login Credentials from Business.manager@SolaTherapy.com",
    dateLastTreatment: "Date of Last Treatment:",
    deviceHelp: "Device Help",
    deviceLocked: "Device Locked?",
    deviceUnlockCode: "Device Unlock Code:",
    email: "Email",
    emailMe: "Email Me",
    emailSent: "Email Sent",
    emailSentMessage: "If there is an associated account with \"_\", then you will receive an email with a link to reset your password momentarily.",
    enterDeviceSessionUnlockCode: "Enter Device Session Unlock Code:",
    firstTreatmentNotCompleted: "First treatment not completed",
    forgotPassword: "Forgot password?",
    forgotPasswordDescription: "Enter your username below and we'll email you a link to reset your password",
    forgotPasswordHeader: "Reset Password",
    generateUnlockCode: "Generate Code to Unlock Device",
    hourglass: "Hourglass icon",
    id: "ID:",
    invalidClientCredentials: "Invalid client ID or secret",
    invalidToken: "Invalid Token",
    lastName: "Last name",
    lockedOutProcedure1: "After 10 treatments without connection to the internet, the Solá Therapy Laser Device will be locked down.",
    lockedOutProcedure2: "To unlock the device, enter the Session Lockout Code found on the Device below and get the Response Code to type back in the Device.",
    lockedOutProcedure3: "Note: Session Lockout Codes will change if device is restarted.",
    login: "Log In",
    loginError: "Login Error",
    loginHeader: "UroShape Login",
    logout: "Log Out",
    missingUsernamePassword: "Username and/or Password is missing!",
    name: "Name",
    networkRequestFailed: "Network request failed! Check your internet connectivity.",
    newPassword: "New Password",
    newPatient: "New Patient",
    newPatientCreated: "The patient has been successfully registered",
    newProvider: "New Provider",
    noPatientsFound: "No Patients Found!",
    npi: "NPI",
    ok: "OK",
    openPaymentPortal: "Open payment portal",
    organizationName: "Organization Name",
    organizationNameInUse: "That organization name is already in use. Please change the name and try again.",
    pageNotFound: "Page Not Found",
    password: "Password",
    passwordChangeSuccess: "Password successfully changed",
    passwordDoesNotMatch: "Passwords do not match",
    passwordError: "Password Error",
    passwordRequirements: "Passwords must include uppercase, lowercase, a number, and a symbol (8-128 characters).",
    patient: "Patient",
    patientConsentComplete: "The patient has signed the patient's consent form and the provider has it on file",
    patientConsentConfirmation: "Please confirm that the patient has signed the patient's consent form and the provider has it on the file",
    patientInfo: "Patient's info",
    patientSearchHint: "Search Patient",
    pay: "Pay",
    paymentAmountSuccess: "Your payment of $_ has been successfully completed",
    paymentCard: "Paying with card:",
    paymentDetails: "Payment Details",
    paymentError: "Payment Error",
    paymentFailure: "Payment Failure!",
    paymentFailureText: "There was an error in processing your payment. Click the link below to open payment portal.",
    paymentPortal: "Payment Portal",
    paymentSuccess: "Payment Success!",
    phoneNumber: "10-digit phone number",
    priceToPatient: "Retail Price to Patient",
    progressWillBeLost: "Any progress will be lost.",
    promiseExpiredOn: "Patient's SoLá Promise expired on:",
    promiseExpiresOn: "Patient's SoLá Promise expires on:",
    providerDetails: "Provider Details",
    providerID: "Provider ID",
    providerInfo: "Provider's info",
    refundNotice: "All sales are final. Refunds may be provided at the sole discretion of Uroshape, LLC.",
    registerNewProvider: "Register new provider",
    registrationError: "Registration Error",
    registrationSuccess: "Registration Success",
    registrationSuccessMsg: "New provider account registered! Your username is \"_\".",
    repeatedTreatmentPaid: "Repeated treatment has been successfully paid!",
    repeatTreatment: "Repeat Treatment",
    resetPassword: "Reset Password",
    returnToHomeScreen: "Return to Home Screen",
    secureCheckout: "Secure checkout through Authorize.net",
    sessionTimedOut: "Session has Timed Out.",
    setNewPassword: "Set New Password",
    signUpPatient: "Sign up new patient",
    solaTherapyLogo: "Sola Therapy Logo",
    standardTreatmentCompleted: "Standard treatment series completed",
    stayOnCurrentPage: "Stay on Current Page",
    treatmentExpired: "Sorry, but either you have used all of available treatments, or you were outside of your window for 2 months",
    treatmentNotAvailable: "Either there are no treatments available or you were out of the window for 2 months",
    treatmentsCompleted: "Treatments Completed:",
    treatmentPackageNumber: "Treatment Package Number:",
    treatmentsCompletedAcrossAllPackages: "Treatments completed across all packages",
    treatmentsHaveBeenCompleted: "Treatments have been completed!",
    unknownAddPatientError: "An unknown error occurred while trying to add this patient. Please try again.",
    unknownPaymentError: "An unknown error occurred while trying to pay for this patient. Please try again.",
    unknownRegistrationError: "An unknown error occurred while trying to register this provider. Please try again.",
    unlockCodeError: "Session Unlock Code must be 8 Characters Long",
    upi: "UPI:",
    upiIsInUse: "UPI is in use",
    usDollars: "US $",
    userId: "User ID",
    username: "Username",
    verifyPassword: "Verify Password",
    version: "Version",
    wrongUsernamePassword: "Wrong username or password!",
    yearsOldShortForm: "y.o.",
    youHaveBeenLoggedOut: "You have been logged out due to inactivity",
};
