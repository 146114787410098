import { ICloudUser } from "promenade-js";
import Cloud, { PROJECT_ID } from "../defs/Cloud";
import StorageKeys from "../defs/StorageKeys";
import { USERNAME_PREFIX } from "./ProviderManager";
import SessionManager from "./SessionManager";

interface ICredentials {
    username: string|null;
    password: string|null;
}

export default class LoginManager {
    public static currentUser: ICloudUser|null = null;
    public static currentGroup: string|null = null;

    public static login = async (username: string, password: string): Promise<void> => {
        const currentUser = await Cloud.login(USERNAME_PREFIX + username, password);
        const currentGroup = currentUser.user_groups.length > 0 ? currentUser.user_groups[0].group : null;

        // Check that user is in the correct project and belongs to a user group
        if (currentUser.project !== PROJECT_ID) {
            throw Error("User not in project");
        }
        if (currentGroup === null) {
            throw Error("User not in a user group");
        }

        // User has logged in and has access, save user information.
        LoginManager.currentUser = currentUser;
        LoginManager.currentGroup = currentGroup;
        LoginManager.addCredentials(username, password);

        SessionManager.startSession();
    };

    public static logout = async (): Promise<void> => {
        LoginManager.currentUser = null;
        LoginManager.removeCredentials();
        return Cloud.logout();
    };

    public static isLoggedIn = (): boolean => {
        return LoginManager.currentUser !== null;
    };

    public static addCredentials = (username: string, password: string): void => {
        sessionStorage.setItem(StorageKeys.sessionUsername, username);
        sessionStorage.setItem(StorageKeys.sessionPassword, password);
    };

    public static getCredentials = (): ICredentials => {
        const username = sessionStorage.getItem(StorageKeys.sessionUsername);
        const password = sessionStorage.getItem(StorageKeys.sessionPassword);
        return {username, password};
    };

    public static removeCredentials = (): void => {
        sessionStorage.removeItem(StorageKeys.sessionUsername);
        sessionStorage.removeItem(StorageKeys.sessionPassword);
    };

    public static trySessionLogin(): Promise<void> {
        const {username, password} = LoginManager.getCredentials();

        if (username !== null && password !== null) {
            return LoginManager.login(username, password);
        }

        return Promise.reject("no credentials cached");
    }

    public static forgotPassword = (username: string): Promise<void> => {
        return Cloud.forgotUserPassword(USERNAME_PREFIX + username);
    };

    public static forgotPasswordReset = (uidb64: string, token: string, newPassword: string): Promise<void> => {
        return Cloud.forgotUserPasswordReset(uidb64, token, newPassword);
    };
}
