import { FlexContainer } from "promenade-react-js";
import React from "react";
import { Button } from "semantic-ui-react";

import Body from "../../components/typography/Body";
import Strings from "../../resources/strings";
import HCPBaseScreen from "../base/HCPBaseScreen";
import "./styles/PaymentFailureScreen.css";

export default class PaymentFailureScreen extends HCPBaseScreen<{}, {}> {
    public static path: string  = "/payment/:treatment/failure";

    protected resetScreen(): void {
        if (this.navParams.price === undefined) {
            this.resetNavigationToHome();
        }
    }

    private renderPaymentFailureText = (): JSX.Element => {
        return (
            <FlexContainer alignment="center" className="payment-failure-screen__body-item">
                <Body size={4}>{Strings.t("paymentFailureText")}</Body>
            </FlexContainer>
        );
    };

    private openPaymentPortal = (): void => {
        window.open(this.navParams.paymentUrl);
    };

    private renderButton = (): JSX.Element => {
        return (
            <Button
                color="black"
                content={Strings.t("paymentPortal")}
                size="huge"
                onClick={this.openPaymentPortal}
                className="payment-failure-screen__button payment-failure-screen__body-item"
            />
        );
    };

    protected renderScreen(): JSX.Element {
        return (
            <FlexContainer
                column
                justification="center"
                alignment="center"
                className="payment-failure-screen__container"
            >
                <Body size={5} className="payment-failure-screen__body-item">
                    {Strings.t("paymentFailure")}
                </Body>
                {this.renderPaymentFailureText()}
                {this.renderButton()}
            </FlexContainer>
        );
    }
}
