import { FlexContainer } from "promenade-react-js";
import * as React from "react";
import { Button, Header, Icon, Input } from "semantic-ui-react";

import AlertStack from "../../components/AlertStack";
import Body from "../../components/typography/Body";
import Regex from "../../defs/Regex";
import DeviceManager, { SESSION_UNLOCK_CODE_LENGTH } from "../../managers/DeviceManager";
import Strings from "../../resources/strings";
import HCPBaseScreen from "../base/HCPBaseScreen";
import "./styles/DeviceHelpScreen.css";

interface IDeviceHelpScreenState {
    highlightErrors: boolean;
    unlockCode: string;
    responseCode: string;
}

export default class DeviceHelpScreen extends HCPBaseScreen<{}, IDeviceHelpScreenState> {

    public static path: string = "/device";

    private alertStack: React.RefObject<AlertStack> = React.createRef<AlertStack>();

    public constructor(props: any) {
        super(props);

        this.state = {
            highlightErrors: false,
            unlockCode: "",
            responseCode: "",
        };
    }

    private updateUnlockCode = (value: string): void => this.setState({unlockCode: value});

    private validateUnlockCode = (): boolean =>  {
        return this.state.unlockCode.replace(Regex.whiteSpace, "").length === SESSION_UNLOCK_CODE_LENGTH;
    };

    private handleIconPress = (): void => {
        const alertStack = this.alertStack.current;

        if (alertStack) {
            const lockoutInformation = (): JSX.Element => {
                return (
                    <FlexContainer column>
                        <Body>{Strings.t("lockedOutProcedure1")}</Body>
                        <Body className="device-help-screen__alert-body-fields">
                            {Strings.t("lockedOutProcedure2")}
                        </Body>
                        <Body bold className="device-help-screen__alert-body-fields">
                            {Strings.t("lockedOutProcedure3")}
                        </Body>
                    </FlexContainer>
                );
            };
            alertStack.push(Strings.t("deviceLocked"), lockoutInformation());
        }
    };

    private handleSavePress = (): void => {
        if (!this.validateUnlockCode()) {
            this.setState({highlightErrors: true});
            return;
        }

        const responseCode = DeviceManager.generateResponseCode(this.state.unlockCode);

        this.setState({responseCode});
    };

    private renderHeader(): JSX.Element {
        return (

            <FlexContainer justification="center">
                <Header as="h1" className="device-help-screen__header">
                    {Strings.t("enterDeviceSessionUnlockCode")}
                </Header>
                <button className="device-help-screen__icon-button" onClick={this.handleIconPress}>
                    <Icon
                        name="question circle outline"
                        color="grey"
                        size="large"
                    />
                </button>
            </FlexContainer>
        );
    }

    private renderError(): JSX.Element|null {
        if (!this.state.highlightErrors || this.validateUnlockCode()) {
            return null;
        }
        return (
            <FlexContainer column alignment="center">
                <Body size={2} className="device-help-screen__result-text--error">{Strings.t("unlockCodeError")}</Body>
            </FlexContainer>
        );
    }

    private renderSessionUnlockCodeInput(): JSX.Element {
        return (
            <FlexContainer column alignment="center" className="device-help-screen__code-input">
                <Input
                    className="device-help-screen__input-fields"
                    error={this.state.highlightErrors && !this.validateUnlockCode()}
                    onChange={(event: any, {value}: {value: string}): void => this.updateUnlockCode(value)}
                    size="big"
                    value={this.state.unlockCode}
                />
                {this.renderError()}
                <Button
                    className="device-help-screen__form-button"
                    color="black"
                    content={Strings.t("generateUnlockCode")}
                    onClick={this.handleSavePress}
                    size="big"
                />
            </FlexContainer>
        );
    }

    private renderGeneratedUnlockCode(): JSX.Element|null {
        if (this.state.responseCode === "") {
            return null;
        }
        return (
            <FlexContainer column alignment="center" className="device-help-screen__result-container">
                <Header as="h1">{Strings.t("deviceUnlockCode")}</Header>
                <Body size={4}>{this.state.responseCode}</Body>
            </FlexContainer>
        );
    }

    protected renderScreen(): JSX.Element {
        return (
            <FlexContainer column alignment="center" className="device-help-screen__screen-container">
                <AlertStack ref={this.alertStack} />
                {this.renderBackButton()}
                <FlexContainer column alignment="center" className="device-help-screen__form-container">
                    {this.renderHeader()}
                    {this.renderSessionUnlockCodeInput()}
                    {this.renderGeneratedUnlockCode()}
                </FlexContainer>
            </FlexContainer>
        );
    }
}
