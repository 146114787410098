import { FlexContainer, PromenadeRouter } from "promenade-react-js";
import * as React from "react";
import { Button, Header, Icon, Input, Loader } from "semantic-ui-react";
import AlertStack from "../../components/AlertStack";
import Body from "../../components/typography/Body";
import Regex from "../../defs/Regex";
import ProviderManager, { OrganizationUserError, RootUserError } from "../../managers/ProviderManager";
import ScreenPaths from "../../managers/ScreenManager";
import Strings from "../../resources/strings";
import "./styles/RegisterProviderScreen.css";

const MAX_USERNAME_LENGTH = 24;

interface IRegisterProviderScreenState {
    loading: boolean;
    highlightErrors: boolean;
    picAccountId: string;
    picUserId: string;
    picPassword: string;
    organizationName: string;
    username: string;
    password: string;
    passwordConfirm: string;
    email: string;
}

interface IValidFields {
    picAccountId: boolean;
    picUserId: boolean;
    picPassword: boolean;
    organizationName: boolean;
    password: boolean;
    passwordConfirm: boolean;
    email: boolean;
}

export default class RegisterProviderScreen extends PromenadeRouter<{}, IRegisterProviderScreenState> {
    public static path: string = "/register";
    private valid: IValidFields;
    private alertStack: React.RefObject<AlertStack> = React.createRef<AlertStack>();

    public constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            highlightErrors: false,
            picAccountId: "",
            picUserId: "",
            picPassword: "",
            organizationName: "",
            username: "",
            password: "",
            passwordConfirm: "",
            email: "",
        };

        this.valid = {
            picAccountId: false,
            picUserId: false,
            picPassword: false,
            organizationName: false,
            password: false,
            passwordConfirm: false,
            email: false,
        };
    }

    // Update State

    private updatePicAccountId = (event: any, {value}: {value: string}): void => {
        this.valid.picAccountId = value.replace(Regex.whiteSpace, "").length !== 0;
        this.setState({picAccountId: value});
    };

    private updatePicUserId = (event: any, {value}: {value: string}): void => {
        this.valid.picUserId = value.replace(Regex.whiteSpace, "").length !== 0;
        this.setState({picUserId: value});
    };

    private updatePicPassword = (event: any, {value}: {value: string}): void => {
        this.valid.picPassword = value.replace(Regex.whiteSpace, "").length !== 0;
        this.setState({picPassword: value});
    };

    private updateOrganizationName = (event: any, {value}: {value: string}): void => {
        this.valid.organizationName = value.replace(Regex.whiteSpace, "").length !== 0;
        this.setState({
            organizationName: value,
            username: RegisterProviderScreen.makeSafeUsername(value),
        });
    };

    private updatePassword = (event: any, {value}: {value: string}): void => {
        this.valid.password = !!value.match(Regex.passwordValidation);
        this.valid.passwordConfirm = value === this.state.passwordConfirm && value.length !== 0;
        this.setState({password: value});
    };

    private updatePasswordConfirm = (event: any, {value}: {value: string}): void => {
        this.valid.passwordConfirm = value === this.state.password && this.state.password.length !== 0;
        this.setState({passwordConfirm: value});
    };

    private updateEmail = (event: any, {value}: {value: string}): void => {
        this.valid.email = !!value.match(Regex.emailValidation);
        this.setState({email: value});
    };

    // Form Validation

    private allFieldsAreValid = (): boolean => {
        for (const valid in this.valid) {
            if (!this.valid[valid]) {
                return false;
            }
        }
        return true;
    };

    private shouldAppearInvalid(fieldName: keyof IValidFields): boolean {
        const inputText = this.state[fieldName];
        const valid = this.valid[fieldName];
        return !valid && (this.state.highlightErrors || inputText.length > 0);
    }

    private static makeSafeUsername(userInput: string): string {
        return userInput
            .replace(Regex.unsafeChars, "")
            .trim()
            .replace(Regex.whiteSpace, "_")
            .substr(0, MAX_USERNAME_LENGTH)
            .toLowerCase();
    }

    // UI Logic

    private handleBackPress = (): void => {
        if (this.state.loading) {
            return;
        }
        this.popScreen();
    };

    private handleSubmitPress = async (): Promise<void> => {
        if (!this.allFieldsAreValid()) {
            this.setState({highlightErrors: true});
            return;
        }

        this.setState({loading: true});
        try {
            await ProviderManager.createNewProvider(
                this.state.picAccountId,
                this.state.picUserId,
                this.state.picPassword,
                this.state.organizationName,
                this.state.username,
                this.state.password,
                this.state.email,
            );

            const alertStack = this.alertStack.current;
            if (alertStack) {
                alertStack.push(
                    Strings.t("registrationSuccess"),
                    Strings.t("registrationSuccessMsg").replace("_", this.state.username),
                    false,
                    [{content: Strings.t("ok"), onClick: (): void => this.pushScreen(ScreenPaths.LOGIN)}],
                );
            }

        } catch (e) {
            console.warn(e);
            this.setState({loading: false});

            let errorMessage = Strings.t("unknownRegistrationError");

            if (e.message === "Failed to fetch") {
                errorMessage = Strings.t("networkRequestFailed");
            } else if (e.message === "invalid_client") {
                errorMessage = Strings.t("invalidClientCredentials");
            } else if (e.original && e.original.status === 409) {
                if (e instanceof RootUserError) {
                    errorMessage = Strings.t("clientIdInUse");
                } else if (e instanceof OrganizationUserError) {
                    errorMessage = Strings.t("organizationNameInUse");
                }
            }

            const alertStack = this.alertStack.current;
            if (alertStack) {
                alertStack.push(Strings.t("registrationError"), errorMessage);
            }
        }
    };

    // Rendering

    protected renderBackButton(): JSX.Element {
        return (
            <div className="register-provider-screen__back-button-container">
                <Button size="huge" labelPosition="left" onClick={this.handleBackPress}>
                    <Icon name="chevron left" />
                    {Strings.t("back")}
                </Button>
            </div>
        );
    }

    private renderFormHeader(): JSX.Element {
        return (
            <FlexContainer justification="center">
                <Header as="h1" className="register-provider-screen__form-header">{Strings.t("newProvider")}</Header>
            </FlexContainer>
        );
    }

    private renderPicForm(): JSX.Element {
        return (
            <FlexContainer column className="register-provider-screen__form-group">
                <Header as="h3">
                    {Strings.t("credentialsFromSolaTherapy")}
                </Header>
                <FlexContainer justification="space-between" className="register-provider-screen__input-row">
                    <FlexContainer column className="register-provider-screen__half-width-input">
                        <Body className="register-provider-screen__label">{Strings.t("accountId")}</Body>
                        <Input
                            value={this.state.picAccountId}
                            onChange={this.updatePicAccountId}
                            disabled={this.state.loading}
                            error={this.shouldAppearInvalid("picAccountId")}
                            size="big"
                            className="register-provider-screen__input-field"
                        />
                    </FlexContainer>
                    <FlexContainer column className="register-provider-screen__half-width-input">
                        <Body className="register-provider-screen__label">{Strings.t("userId")}</Body>
                        <Input
                            value={this.state.picUserId}
                            onChange={this.updatePicUserId}
                            disabled={this.state.loading}
                            error={this.shouldAppearInvalid("picUserId")}
                            size="big"
                            className="register-provider-screen__input-field"
                        />
                    </FlexContainer>
                </FlexContainer>
                <FlexContainer
                    column
                    className="register-provider-screen__input-row register-provider-screen__half-width-input"
                >
                    <Body className="register-provider-screen__label">{Strings.t("password")}</Body>
                    <Input
                        value={this.state.picPassword}
                        onChange={this.updatePicPassword}
                        disabled={this.state.loading}
                        error={this.shouldAppearInvalid("picPassword")}
                        size="big"
                        className="register-provider-screen__input-field"
                    />
                </FlexContainer>
            </FlexContainer>
        );
    }

    private renderPasswordHint(): JSX.Element|null {
        if (!this.state.highlightErrors || this.valid.password) {
            return null;
        }
        return (
            <Body color="red" className="register-provider-screen__password-hint">
                {Strings.t("passwordRequirements")}
            </Body>
        );
    }

    private renderProviderForm(): JSX.Element {
        return (
            <FlexContainer column className="register-provider-screen__form-group">
                <Header as="h3">{Strings.t("providerDetails")}</Header>
                <FlexContainer justification="space-between" className="register-provider-screen__input-row">
                    <FlexContainer column className="register-provider-screen__half-width-input">
                        <Body className="register-provider-screen__label">{Strings.t("organizationName")}</Body>
                        <Input
                            disabled={this.state.loading}
                            onChange={this.updateOrganizationName}
                            error={this.shouldAppearInvalid("organizationName")}
                            size="big"
                            className="register-provider-screen__input-field"
                            value={this.state.organizationName}
                        />
                    </FlexContainer>
                    <FlexContainer column className="register-provider-screen__half-width-input">
                        <Body className="register-provider-screen__label">{Strings.t("username")}</Body>
                        <Input
                            disabled
                            size="big"
                            className="register-provider-screen__input-field"
                            value={this.state.username}
                        />
                    </FlexContainer>
                </FlexContainer>
                {this.renderPasswordHint()}
                <FlexContainer justification="space-between" className="register-provider-screen__input-row">
                    <FlexContainer column className="register-provider-screen__half-width-input">
                        <Body className="register-provider-screen__label">{Strings.t("newPassword")}</Body>
                        <Input
                            value={this.state.password}
                            onChange={this.updatePassword}
                            disabled={this.state.loading}
                            error={this.state.highlightErrors && !this.valid.password}
                            size="big"
                            className="register-provider-screen__input-field"
                            type="password"
                        />
                    </FlexContainer>
                    <FlexContainer column className="register-provider-screen__half-width-input">
                        <Body className="register-provider-screen__label">{Strings.t("verifyPassword")}</Body>
                        <Input
                            value={this.state.passwordConfirm}
                            onChange={this.updatePasswordConfirm}
                            disabled={this.state.loading}
                            error={this.shouldAppearInvalid("passwordConfirm")}
                            size="big"
                            className="register-provider-screen__input-field"
                            type="password"
                        />
                    </FlexContainer>
                </FlexContainer>
                <FlexContainer column justification="space-between" className="register-provider-screen__input-row">
                    <Body className="register-provider-screen__label">{Strings.t("email")}</Body>
                    <Input
                        value={this.state.email}
                        onChange={this.updateEmail}
                        disabled={this.state.loading}
                        error={this.shouldAppearInvalid("email")}
                        size="big"
                        className="register-provider-screen__input-field"
                        type="email"
                    />
                </FlexContainer>
            </FlexContainer>
        );
    }

    private renderSubmitButton(): JSX.Element {
        return (
            <FlexContainer justification="center">
                <Button
                    content={Strings.t("registerNewProvider")}
                    color="black"
                    size="big"
                    disabled={this.state.loading}
                    className="register-provider-screen__form-button"
                    onClick={this.handleSubmitPress}
                />
            </FlexContainer>
        );
    }

    public render(): JSX.Element {
        return (
            <FlexContainer
                column
                alignment="center"
                className="register-provider-screen__screen-container"
            >
                {this.renderBackButton()}
                <FlexContainer column className="register-provider-screen__form-container">
                    {this.renderFormHeader()}
                    {this.renderPicForm()}
                    {this.renderProviderForm()}
                    {this.renderSubmitButton()}
                </FlexContainer>
                <Loader active={this.state.loading} />
                <AlertStack ref={this.alertStack} />
            </FlexContainer>
        );
    }
}
