import phone from "phone";
import { FlexContainer } from "promenade-react-js";
import * as React from "react";
import { Button, Checkbox, Header, Input, Item, Loader } from "semantic-ui-react";

import AlertStack from "../../components/AlertStack";
import Body from "../../components/typography/Body";
import Regex from "../../defs/Regex";
import PatientManager from "../../managers/PatientManager";
import PaymentManager from "../../managers/PaymentManager";
import ScreenPaths, { NEW_TREATMENT } from "../../managers/ScreenManager";
import Patient, { INewPatient } from "../../models/Patient";
import Strings from "../../resources/strings";
import NavParams from "../../types/NavParams";
import HCPBaseScreen from "../base/HCPBaseScreen";
import "./styles/AddPatientScreen.css";

interface IAddPatientScreenState {
    highlightErrors: boolean;
    loading: boolean;
    patient: INewPatient;
}

export interface IAddPatientScreenNavParams {
    patient: INewPatient;
}

const DEFAULT_PATIENT_STATE: INewPatient = {
    lastName: "",
    upi: "",
    phoneNumber: "",
    providerName: "",
    providerNPI: "",
    consent: false,
};

export default class AddPatientScreen extends HCPBaseScreen<{}, IAddPatientScreenState> {
    public static path: string = "/add";
    private alertStack: React.RefObject<AlertStack> = React.createRef<AlertStack>();
    private lastInvalidUPI: string|null;

    private valid: {
        lastName: boolean;
        phoneNumber: boolean;
        providerName: boolean;
        providerNPI: boolean;
    };

    public constructor(props: any) {
        super (props);

        this.state = {
            highlightErrors: false,
            loading: false,
            patient: NavParams(this.navParams as IAddPatientScreenNavParams).patient || DEFAULT_PATIENT_STATE,
        };

        this.valid = {
            lastName: false,
            phoneNumber: false,
            providerName: false,
            providerNPI: false,
        };

        this.lastInvalidUPI = null;
    }

    // Update State

    private updateLastName = (value: string): void => this.updatePatientField("lastName", value);
    private updatePhoneNumber = (value: string): void => this.updatePatientField("phoneNumber", value);
    private updateProviderName = (value: string): void => this.updatePatientField("providerName", value);
    private updateProviderNPI = (value: string): void => this.updatePatientField("providerNPI", value);
    private togglePatientConsent = (): void => this.updatePatientField("consent", !this.state.patient.consent);

    private updatePatientField = (field: string, value: string|boolean): void => {
        const patient = Object.assign({}, this.state.patient);
        patient[field] = value;
        this.setState({patient});
    };

    // Event Handlers

    private handleSavePress = async (): Promise<void> => {
        if (!this.validateFields() || !this.state.patient.consent) {
            this.setState({highlightErrors: true});
            return;
        }

        this.setState({loading: true});
        try {
            const upi = Patient.generateUPI(this.state.patient.lastName, this.state.patient.phoneNumber);
            const upiInUse = await PatientManager.upiIsInUse("", upi);
            if (upiInUse) {
                const alertStack = this.alertStack.current;
                if (alertStack) {
                    alertStack.push(Strings.t("addPatientError"), Strings.t("upiIsInUse"));
                }
                this.lastInvalidUPI = upi;
                this.setState({loading: false, highlightErrors: true});
                return;
            }

            const paymentPatient = await PaymentManager.addClient(
                this.state.patient.lastName,
                this.state.patient.phoneNumber,
            );

            const patient = Patient.FromScratch(this.state.patient);
            patient.clientId = paymentPatient.properties.Client;

            this.pushScreen(
                ScreenPaths.PAYMENT_DETAILS,
                {patient},
                {treatment: NEW_TREATMENT},
            );

        } catch (e) {
            this.handleSaveErrors(e);
            return;
        }
    };

    private handleSaveErrors = (error: Error): void => {
        const title = Strings.t("addPatientError");
        let message = Strings.t("unknownAddPatientError");
        if (error.message === "Failed to fetch") {
            message = Strings.t("networkRequestFailed");
        }

        console.error(error);
        const alertStack = this.alertStack.current;
        if (alertStack) {
            alertStack.push(title, message);
        }
        this.setState({loading: false});
    };

    protected handleBackPress = (): void => {
        this.resetNavigationToHome();
    };

    // Form Validation

    private validateFields = (): boolean => {
        for (const valid in this.valid) {
            if (!this.valid[valid]) {
                return false;
            }
        }
        return true;
    };

    private validateSimpleInput = (inputText: string, valid: boolean): boolean => {
        if (this.state.highlightErrors) {
            return valid;
        }
        return inputText.length === 0 || valid;
    };

    private validateLastName = (): boolean => {
        this.valid.lastName =
            this.state.patient.lastName.replace(Regex.whiteSpace, "").length !== 0
            && this.lastInvalidUPI !== Patient.generateUPI(this.state.patient.lastName, this.state.patient.phoneNumber);
        return this.validateSimpleInput(this.state.patient.lastName, this.valid.lastName);
    };

    private validatePhoneNumber = (): boolean => {
        this.valid.phoneNumber =
            Regex.phoneNumberValidation.test(this.state.patient.phoneNumber)
            && phone(this.state.patient.phoneNumber).length > 0
            && this.lastInvalidUPI !== Patient.generateUPI(this.state.patient.lastName, this.state.patient.phoneNumber);

        if (this.state.highlightErrors) {
            return this.valid.phoneNumber;
        }
        return (
            Regex.phoneNumberValidation.test(this.state.patient.phoneNumber)
            && (
                this.state.patient.phoneNumber.length === 0
                || this.state.patient.phoneNumber.replace(Regex.whiteSpace, "").length !== 0
            )
        );
    };

    private validateProviderName = (): boolean => {
        this.valid.providerName = this.state.patient.providerName.replace(Regex.whiteSpace, "").length !== 0;
        return this.validateSimpleInput(this.state.patient.providerName, this.valid.providerName);
    };

    private validateProviderNPI = (): boolean => {
        this.valid.providerNPI =
            Regex.providerNPIValidation.test(this.state.patient.providerNPI)
            || this.state.patient.providerNPI.length === 0;
        return this.valid.providerNPI;
    };

    // Render Functions

    private renderFormHeader(): JSX.Element {
        return (
            <FlexContainer justification="center">
                <Header as="h1" className="add-patient-screen__form-header">{Strings.t("newPatient")}</Header>
            </FlexContainer>
        );
    }

    private renderPatientForm(): JSX.Element {
        return (
            <FlexContainer column className="add-patient-screen__form-group">
                <Header as="h3">{Strings.t("patientInfo")}</Header>
                <FlexContainer justification="space-between">
                    <FlexContainer column className="add-patient-screen__large-input-container">
                        <Body className="add-patient-screen__label">{Strings.t("lastName")}</Body>
                        <Input
                            className="add-patient-screen__input-fields"
                            disabled={this.state.loading}
                            error={!this.validateLastName()}
                            onChange={(event: any, {value}: {value: string}): void => this.updateLastName(value)}
                            size="big"
                            value={this.state.patient.lastName}
                        />
                    </FlexContainer>
                    <FlexContainer column className="add-patient-screen__large-input-container">
                        <Body className="add-patient-screen__label">{Strings.t("phoneNumber")}</Body>
                        <Input
                            className="add-patient-screen__input-fields"
                            disabled={this.state.loading}
                            error={!this.validatePhoneNumber()}
                            onChange={(event: any, {value}: {value: string}): void => this.updatePhoneNumber(value)}
                            size="big"
                            value={this.state.patient.phoneNumber}
                        />
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        );
    }

    private renderProviderForm(): JSX.Element {
        return (
            <FlexContainer column className="add-patient-screen__form-group">
                <Header as="h3">
                    {Strings.t("providerInfo")}
                </Header>
                <FlexContainer justification="space-between">
                    <FlexContainer column className="add-patient-screen__large-input-container">
                        <Body className="add-patient-screen__label">{Strings.t("name")}</Body>
                        <Input
                            className="add-patient-screen__input-fields"
                            disabled={this.state.loading}
                            error={!this.validateProviderName()}
                            onChange={(event: any, {value}: {value: string}): void => this.updateProviderName(value)}
                            size="big"
                            value={this.state.patient.providerName}
                        />
                    </FlexContainer>
                    <FlexContainer column className="add-patient-screen__large-input-container">
                        <Body className="add-patient-screen__label">{Strings.t("npi")}</Body>
                        <Input
                            className="add-patient-screen__input-fields"
                            disabled={this.state.loading}
                            error={!this.validateProviderNPI()}
                            onChange={(event: any, {value}: {value: string}): void => this.updateProviderNPI(value)}
                            size="big"
                            value={this.state.patient.providerNPI}
                        />
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        );
    }

    private renderConsentForm(): JSX.Element {

        const textColor = this.state.highlightErrors && !this.state.patient.consent
            ? "add-patient-screen__consent-string--error"
            : "add-patient-screen__consent-string";
        return (
            <FlexContainer alignment="center" className="add-patient-screen__form-group">
                <Checkbox
                    className="add-patient-screen__consent-checkbox"
                    onChange={this.togglePatientConsent}
                    checked={this.state.patient.consent}
                />
                <Item.Group link className="add-patient-screen__consent-item">
                    <Item onClick={this.togglePatientConsent}>
                        <Body size={2} className={textColor}>{Strings.t("patientConsentConfirmation")}</Body>
                    </Item>
                </Item.Group>
            </FlexContainer>
        );
    }

    private renderSaveButton(): JSX.Element {
        return (
            <FlexContainer justification="center">
                <Button
                    className="add-patient-screen__form-button"
                    color="black"
                    content={Strings.t("signUpPatient")}
                    disabled={this.state.loading}
                    onClick={this.handleSavePress}
                    size="big"
                />
            </FlexContainer>
        );
    }

    protected renderScreen(): JSX.Element {
        return (
            <FlexContainer column alignment="center" className="add-patient-screen__screen-container">
                <AlertStack ref={this.alertStack} />
                <Loader active={this.state.loading} />
                {this.renderBackButton()}
                <FlexContainer column className="add-patient-screen__form-container">
                    {this.renderFormHeader()}
                    {this.renderPatientForm()}
                    {this.renderProviderForm()}
                    {this.renderConsentForm()}
                    {this.renderSaveButton()}
                </FlexContainer>
            </FlexContainer>
        );
    }

}
