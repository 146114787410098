import { FlexContainer, PromenadeRouter } from "promenade-react-js";
import * as React from "react";
import { Button, Input, Loader } from "semantic-ui-react";

import AlertStack from "../../components/AlertStack";
import Body from "../../components/typography/Body";
import Regex from "../../defs/Regex";
import LoginManager from "../../managers/LoginManager";
import Strings from "../../resources/strings";
import "./styles/PasswordResetScreen.css";

interface IPasswordResetScreenState {
    password: string;
    repeatPassword: string;
    highlightErrors: boolean;
    loading: boolean;
}

export default class PasswordResetScreen extends PromenadeRouter<{}, IPasswordResetScreenState> {
    public static path: string  = "/reset-password/:uidb64/:token";
    private alertStack: React.RefObject<AlertStack> = React.createRef<AlertStack>();

    public constructor(props: any) {
      super (props);
      this.state = {
          password: "",
          repeatPassword: "",
          highlightErrors: false,
          loading: false,
        };
    }

    private updatePassword(input: string): void {
        this.setState({password: input});
    }

    private updateRepeatPassword(input: string): void {
        this.setState({repeatPassword: input});
    }

    private validatePassword = (): boolean => {
        return !!this.state.password.match(Regex.passwordValidation);
    };

    private validateRepeatPassword = (): boolean => {
        return this.state.password === this.state.repeatPassword;
    };

    private handleResetPasswordButtonClick = async (): Promise<void> => {
        if (!this.validatePassword() || !this.validateRepeatPassword()) {
            this.setState({highlightErrors: true});
            return;
        }
        this.setState({loading: true});

        const alertStack = this.alertStack.current;
        try {
            await LoginManager.forgotPasswordReset(
                this.urlParams.uidb64,
                this.urlParams.token,
                this.state.password,
            );
        } catch (e) {
            console.warn(e);
            if (e.status === 400 && alertStack) {
                alertStack.push(
                    Strings.t("passwordError"),
                    Strings.t("invalidToken"),
                    false,
                    [{onClick: this.resetNavigation, content: Strings.t("ok")}],
                );
            }
            this.setState({loading: false, highlightErrors: true});
            return;
        }
        if (alertStack) {
            alertStack.push(
                Strings.t("passwordChangeSuccess"),
                Strings.t("backToLoginPrompt"),
                false,
                [{onClick: this.resetNavigation, content: "OK"}],
            );
        }
        this.setState({loading: false});
    };

    private renderPasswordRequirements = (): JSX.Element|null => {
        if (this.validatePassword() || !this.state.highlightErrors) {
            return null;
        }
        return (
            <Body className="password-reset-screen__password-requirements">
                {Strings.t("passwordRequirements")}
            </Body>
        );
    };

    private renderPasswordDoesNotMatch = (): JSX.Element|null => {
        if (this.validateRepeatPassword() || !this.state.highlightErrors) {
            return null;
        }
        return (
            <Body className="password-reset-screen__password-requirements">
                {Strings.t("passwordDoesNotMatch")}
            </Body>
        );
    };

    private renderPasswordInput = (): JSX.Element => {
        return (
            <Input
                className="password-reset-screen__form-fields"
                onChange={(event: any, {value}: {value: string}): void => this.updatePassword(value)}
                error={!this.validatePassword() && this.state.highlightErrors}
                type="password"
                disabled={this.state.loading}
            />
        );
    };

    private renderRepeatPasswordInput = (): JSX.Element => {
        return (
            <Input
                className="password-reset-screen__form-fields"
                onChange={(event: any, {value}: {value: string}): void => this.updateRepeatPassword(value)}
                error={!this.validateRepeatPassword() && this.state.highlightErrors}
                type="password"
                disabled={this.state.loading}
            />
        );
    };

    private renderButton = (): JSX.Element => {
        return (
            <Button
                className="password-reset-screen__button-field"
                color="black"
                content={Strings.t("resetPassword")}
                onClick={this.handleResetPasswordButtonClick}
                size="big"
                disabled={this.state.loading}
            />
        );
    };

    public render(): JSX.Element {
        return (
            <FlexContainer justification="center" alignment="center" className="password-reset-screen__container">
                <AlertStack ref={this.alertStack} />
                <Loader active={this.state.loading} />
                <FlexContainer column className="password-reset-screen__form-container">
                    <Body size={5}>{Strings.t("setNewPassword")}</Body>
                    <Body className="password-reset-screen__label">{Strings.t("newPassword")}</Body>
                    {this.renderPasswordInput()}
                    {this.renderPasswordRequirements()}
                    <Body className="password-reset-screen__label">{Strings.t("verifyPassword")}</Body>
                    {this.renderRepeatPasswordInput()}
                    {this.renderPasswordDoesNotMatch()}
                    {this.renderButton()}
                </FlexContainer>
            </FlexContainer>
        );
    }
}
