import * as React from "react";
import {Button, Modal, SemanticCOLORS} from "semantic-ui-react";
import Strings from "../resources/strings";

interface IAlertButton {
    content: string;
    onClick?: () => void;
    color?: SemanticCOLORS;
}

interface IAlert {
    id: number;
    title: string;
    content: string|JSX.Element;
    dismissible: boolean;
    buttons: IAlertButton[];
}

interface IAlertStackState {
    alerts: IAlert[];
}

const DEFAULT_BUTTONS: IAlertButton[] = [{content: Strings.t("ok")}];

export default class AlertStack extends React.Component<{}, IAlertStackState> {
    private nextId: number;

    constructor(props: any) {
        super(props);
        this.state = {
            alerts: [],
        };
        this.nextId = 0;
    }

    /**
     * Opens a new alert on top of all the other alerts
     * @returns ID of the new alert
     */
    public push = (
        title: string,
        content: string|JSX.Element,
        dismissible: boolean = true,
        buttons: IAlertButton[] = DEFAULT_BUTTONS,
    ): number => {
        const alerts = this.state.alerts;
        const newAlert = {id: this.nextId++, title, content, dismissible, buttons};
        alerts.push(newAlert);
        this.setState({alerts});
        return newAlert.id;
    };

    /**
     * Closes the alert on the top of the stack
     * @returns ID of the popped alert
     */
    public pop = (): number|null => {
        const alerts = this.state.alerts;
        const removedAlert = alerts.pop();
        if (removedAlert === undefined) {
            console.warn("Tried to pop from an empty alert stack");
            return null;
        }
        this.setState({alerts});
        return removedAlert.id;
    };

    /**
     * Closes the alert with the given ID
     * The closed alert can be anywhere in the stack; it doesn't have to be on top
     */
    public close = (id: number): void => {
        const alerts = this.state.alerts;
        const index = alerts.findIndex((alert: IAlert) => alert.id === id);
        if (index === -1) {
            console.warn("Tried to close an alert with non-existent ID: " + id);
            return;
        }
        alerts.splice(index, 1);
        this.setState({alerts});
    };

    private handleButtonClicked = (button: IAlertButton, alert: IAlert): void => {
        if (typeof button.onClick === "function") {
            button.onClick();
        }
        this.close(alert.id);
    };

    private renderButton = (button: IAlertButton, index: number, alert: IAlert): JSX.Element => {
        return (
            <Button
                content={button.content}
                color={button.color}
                onClick={(): void => this.handleButtonClicked(button, alert)}
                key={index}
            />
        );
    };

    private renderAlert = (alert: IAlert): JSX.Element => {
        const buttons = alert.buttons.map((button: IAlertButton, index: number): JSX.Element => {
            return this.renderButton(button, index, alert);
        });

        return (
            <Modal
                open
                size="small"
                onClose={(): void => this.close(alert.id)}
                closeOnEscape={alert.dismissible}
                closeOnDimmerClick={alert.dismissible}
                key={alert.id}
            >
                <Modal.Header>{alert.title}</Modal.Header>
                <Modal.Content>{alert.content}</Modal.Content>
                <Modal.Actions>
                    {buttons}
                </Modal.Actions>
            </Modal>
        );
    };

    public render(): JSX.Element {
        return (
            <div>
                {this.state.alerts.map(this.renderAlert)}
            </div>

        );
    }
}
