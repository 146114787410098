import CryptoJS from "crypto-js";

export const SESSION_UNLOCK_CODE_LENGTH = 8;

export default class DeviceManager {

    public static generateResponseCode = (sessionUnlockCode: string): string => {
        const key = DeviceManager.getKey();

        const hash = CryptoJS.HmacSHA1(sessionUnlockCode, key);

        return hash.toString().substring(0, SESSION_UNLOCK_CODE_LENGTH);
    };

    private static getKey(): string {
        return atob("J3FeOXM2V2NDIytaeS9RZT1PdmRkRH1bYjcuSXI+LkY=");
    }
}
