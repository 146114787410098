import { FlexContainer, PromenadeRouter } from "promenade-react-js";
import * as React from "react";
import { Button, Icon } from "semantic-ui-react";

import Body from "../../components/typography/Body";
import LoginManager from "../../managers/LoginManager";
import PaymentManager from "../../managers/PaymentManager";
import { USERNAME_PREFIX } from "../../managers/ProviderManager";
import ScreenPaths from "../../managers/ScreenManager";
import SessionManager from "../../managers/SessionManager";
import SolaTherapyLogo from "../../resources/images/SolaTherapyLogo.png";
import Strings from "../../resources/strings";
import "./styles/HCPBaseScreen.css";

export default class HCPBaseScreen<Props, State> extends PromenadeRouter<Props, State> {
    private mounted: boolean = false;
    private gettingPaymentUrl: boolean = false;

    public constructor(props: any) {
        super(props);

        // Register handler for when the session expires
        if (SessionManager.expirationHandlers.length === 0) {
            SessionManager.expirationHandlers.push(async (): Promise<void> => {
                await LoginManager.logout();
                this.resetNavigation(ScreenPaths.DEFAULT, {sessionTimeout: true});
            });
        }
    }

    public componentWillMount(): void {
        this.authenticateUser();
    }

    public componentDidMount(): void {
        this.mounted = true;
    }

    public componentWillUnmount(): void {
        this.mounted = false;
    }

    protected resetScreen(): void {
        // Re-render screen with currentUser for title bar username
        this.forceUpdate();
    }

    protected safeSetState(
        updater: ((state: any, prop: any) => void)|object,
        callback: () => void = (): void => undefined,
    ): void {
        if (this.mounted) {
            this.setState(updater, callback);
        } else {
            if (typeof updater === "object") {
                Object.assign(this.state, updater);
            } else if (typeof updater === "function") {
                const newState = updater(this.state, this.props);
                Object.assign(this.state, newState);
            }
            if (typeof callback === "function") {
                callback();
            }
        }
    }

    private handleLogoutPressed = (): Promise<void> => {
        SessionManager.endSession();
        this.resetNavigation();
        return LoginManager.logout();
    };

    protected authenticateUser = async (): Promise<void> => {
        if (!LoginManager.isLoggedIn()) {
            try {
                await LoginManager.trySessionLogin();
            } catch (e) {
                // User login failed, return to login screen.
                this.resetNavigation();
                return;
            }
        }
        this.resetScreen();
    };

    protected handleBackPress = (): void => {
        this.popScreen();
    };

    protected handleLogoPressed = (): void => {
        this.resetNavigationToHome();
    };

    protected handleDeviceHelpPressed = (): void => {
        this.pushScreen(ScreenPaths.DEVICE_HELP);
    };

    private handlePaymentPortalPress = async (): Promise<void> => {
        // Do not try to get payment URL if already getting one
        if (this.gettingPaymentUrl) {
            return;
        }

        this.gettingPaymentUrl = true;
        try {
            const paymentUrl = await PaymentManager.getPaymentPortalUrl();
            window.open(paymentUrl);
        } catch (e) {
            console.warn(e);
        } finally {
            this.gettingPaymentUrl = false;
        }
    };

    protected renderBackButton(): JSX.Element {
        return (
            <div className="hcp-base-screen__back-button-container">
                <Button size="huge" labelPosition="left" onClick={this.handleBackPress}>
                    <Icon name="chevron left" />
                    {Strings.t("back")}
                </Button>
            </div>
        );
    }

    private renderLogo = (): JSX.Element => {
        return (
            <img
                src={SolaTherapyLogo}
                className="hcp-base-screen__logo"
                alt={Strings.t("solaTherapyLogo")}
                onClick={this.handleLogoPressed}
            />
        );
    };

    protected renderTitleBar(): JSX.Element {
        const username = (LoginManager.currentUser !== null)
            ? LoginManager.currentUser.username.substring(USERNAME_PREFIX.length)
            : "--";

        return (
            <FlexContainer column alignment="center" className="hcp-base-screen__title-bar">
                <FlexContainer
                    alignment="center"
                    className="hcp-base-screen__title-bar-contents"
                    justification="space-between"
                >
                    {this.renderLogo()}
                    <FlexContainer alignment="center">
                        <button className="hcp-base-screen__username-button">
                            <FlexContainer alignment="center">
                                <Icon name="user circle" size="big" />
                                <Body size={2}>{username}</Body>
                            </FlexContainer>
                        </button>
                        <Button content={Strings.t("logout")} onClick={this.handleLogoutPressed} />
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        );
    }

    protected renderScreen(): JSX.Element {
        throw Error ("Must be implemented by inheriting class of HCPBaseScreen");
    }

    protected renderFooter(): JSX.Element {
        // Render either the Device Help or Payment Portal link depending on the user's permissions
        let customActionButton = (
            <Body size={2} onClick={this.handlePaymentPortalPress}>
                {Strings.t("paymentPortal")}
            </Body>
        );
        if (LoginManager.currentUser !== null && LoginManager.currentUser.is_project_superuser) {
            customActionButton = (
                <Body size={2} onClick={this.handleDeviceHelpPressed}>{Strings.t("deviceHelp")}</Body>
            );
        }

        return (
            <FlexContainer
                alignment="center"
                className="hcp-base-screen__footer"
                justification="space-between"
            >
                <FlexContainer className="hcp-base-screen__footer-copyright">
                    <Icon size="large" name="copyright outline" />
                    <Body size={2} className="hcp-base-screen__footer-copyright">{Strings.t("copyright")}</Body>
                </FlexContainer>

                {customActionButton}

                <FlexContainer alignment="center">
                    <Icon
                        name="mail"
                        className="hcp-base-screen__footer-contact-info-icon"
                        flipped="horizontally"
                        circular
                        inverted
                        color="grey"
                    />
                    <Body size={2} href={"mailto:" + Strings.t("contactEmail")}>
                        {Strings.t("contactEmail")}
                    </Body>
                </FlexContainer>

                <FlexContainer alignment="center">
                    <Icon
                        name="phone"
                        className="hcp-base-screen__footer-contact-info-icon"
                        flipped="horizontally"
                        circular
                        inverted
                        color="grey"
                    />
                    <Body size={2}>{Strings.t("contactPhoneNumber")}</Body>
                </FlexContainer>
            </FlexContainer>
        );
    }

    public render(): JSX.Element {
        return (
            <FlexContainer column alignment="center" className="hcp-base-screen__base-screen-container">
                {this.renderTitleBar()}
                <FlexContainer column alignment="center" className="hcp-base-screen__screen-container">
                    {this.renderScreen()}
                </FlexContainer>
                {this.renderFooter()}
            </FlexContainer>
        );
    }
}
