import { FlexContainer } from "promenade-react-js";
import * as React from "react";
import { Button, Input, Modal } from "semantic-ui-react";

import AlertStack from "../components/AlertStack";
import Body from "../components/typography/Body";
import Regex from "../defs/Regex";
import LoginManager from "../managers/LoginManager";
import Strings from "../resources/strings";
import "./styles/ForgotPasswordModal.css";

interface IForgotPasswordState {
    username: string;
    open: boolean;
    highlightErrors: boolean;
}

export default class ForgotPasswordModal extends React.Component<{}, IForgotPasswordState> {

    private alertStack: React.RefObject<AlertStack> = React.createRef<AlertStack>();
    private usernameValid: boolean;

    constructor(props: any) {
        super(props);

        this.state = {
            username: "",
            open: false,
            highlightErrors: false,
        };

        this.usernameValid = false;

    }

    private updateUserName = (value: string): void => this.setState({username: value});

    public show = (): void => {
        this.setState({open: true, username: "", highlightErrors: false});
    };

    private close = (): void => {
        this.setState({open: false});
    };

    private handleEmailMePress = async (): Promise<void> => {
        if (!this.usernameValid) {
            this.setState({highlightErrors: true});
            return;
        }

        await LoginManager.forgotPassword(this.state.username);

        const alertStack = this.alertStack.current;
        if (alertStack) {
            alertStack.push(
                Strings.t("emailSent"),
                Strings.t("emailSentMessage").replace("_", this.state.username),
                false,
                [{content: Strings.t("ok"), onClick: this.close}],
            );
        }
    };

    private handleInputKeyPressed = (event: KeyboardEvent): void => {
        if (event.key === "Enter") {
            this.handleEmailMePress();
        }
    };

    private validateUsernameNotEmpty = (): boolean => {
        this.usernameValid = this.state.username.replace(Regex.whiteSpace, "").length !== 0;
        return this.state.highlightErrors ? this.usernameValid : this.state.username.length === 0 || this.usernameValid;
    };

    private renderHeader(): JSX.Element {
        return (
            <Body size={5} className="forgot-password-modal__header">
                {Strings.t("forgotPasswordHeader")}
            </Body>
        );
    }

    private renderDescription(): JSX.Element {
        return (
            <Body size={2} className="forgot-password-modal__description">
                {Strings.t("forgotPasswordDescription")}
            </Body>
        );
    }

    private renderAccountNumInput(): JSX.Element {
        return (
            <FlexContainer column justification="space-between" className="forgot-password-modal__input-container">
                <Body className="forgot-password-modal__input-label">{Strings.t("username")}</Body>
                <Input
                    error={!this.validateUsernameNotEmpty()}
                    onChange={(event: any, {value}: {value: string}): void => this.updateUserName(value)}
                    onKeyPress={this.handleInputKeyPressed}
                    size="big"
                    value={this.state.username}
                />
            </FlexContainer>
        );
    }

    private renderButtons(): JSX.Element {
        return (
            <FlexContainer column className="forgot-password-modal__button-container" alignment="center">
                <Button
                    className="forgot-password-modal__button"
                    color="black"
                    content={Strings.t("emailMe")}
                    onClick={this.handleEmailMePress}
                    size="big"
                />
                <Body size={2} onClick={this.close} className="forgot-password-modal__item">
                    {Strings.t("backToLogin")}
                </Body>
            </FlexContainer>
        );
    }

    public render(): JSX.Element {
        return (
            <div>
                <AlertStack ref={this.alertStack} />
                <Modal open={this.state.open} onClose={this.close} size="tiny">
                    <Modal.Content>
                        <FlexContainer
                            alignment="center"
                            className="forgot-password-modal__modal-container"
                            column
                            justification="center"
                        >
                            {this.renderHeader()}
                            {this.renderDescription()}
                            {this.renderAccountNumInput()}
                            {this.renderButtons()}
                        </FlexContainer>
                    </Modal.Content>
                </Modal>
            </div>
        );

    }
}
