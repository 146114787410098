import moment from "moment";
import { FlexContainer } from "promenade-react-js";
import * as React from "react";
import { Slide, toast, ToastContainer } from "react-toastify";
import { Icon, Input, Label, Loader } from "semantic-ui-react";

import Body from "../../components/typography/Body";
import LoginManager from "../../managers/LoginManager";
import PatientManager from "../../managers/PatientManager";
import ScreenPaths, { ADDITIONAL_TREATMENT, NEW_TREATMENT } from "../../managers/ScreenManager";
import Patient, { DEFAULT_TREATMENT_EXPIRATION_DATE } from "../../models/Patient";
import Strings from "../../resources/strings";
import { titleCase } from "../../utils/String";
import HCPBaseScreen from "../base/HCPBaseScreen";
import "./styles/HomeScreen.css";

interface IHomeScreenState {
    loading: boolean;
    patients: Patient[];
    searchQuery: string;
}

export default class HomeScreen extends HCPBaseScreen<{}, IHomeScreenState> {
    public static path: string = "/home";

    public constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            patients: [],
            searchQuery: "",
        };

        if (LoginManager.isLoggedIn()) {
            this.resetScreen();
        }
    }

    private renderNotification = (message: string): JSX.Element => {
        return (
            <FlexContainer justification="center" alignment="center">
                <Icon name="check circle outline" size="big" className="home-screen__notification-check-icon" />
                <Body size={3}>{message}</Body>
            </FlexContainer>
        );
    };

    public componentDidMount(): void {
        super.componentDidMount();

        switch (this.navParams.paymentType) {
            case ADDITIONAL_TREATMENT:
                toast(() => this.renderNotification(Strings.t("additionalTreatmentPaid")));
                break;
            case NEW_TREATMENT:
                toast(() => this.renderNotification(Strings.t("newPatientCreated")));
                break;
        }
    }

    protected resetScreen = (): void => {
        this.safeSetState({loading: true});
        this.loadPatients();
    };

    private loadPatients = async (): Promise<void> => {
        const patients = await PatientManager.getPatients();
        this.safeSetState({patients, loading: false});
    };

    private handleAddNewPatient = (): void => {
        this.pushScreen(ScreenPaths.ADD_PATIENT);
    };

    private handlePatientClicked = (patient: Patient): void => {
        this.pushScreen(ScreenPaths.PATIENT_DETAILS, {patient}, {patientID: patient.id});
    };

    private sortedPatients(patients: Patient[]): Patient[] {
        return patients.sort((a: Patient, b: Patient): number => {
            const treatmentsRemainingA = a.treatmentsRemaining;
            const treatmentsRemainingB = b.treatmentsRemaining;

            if (treatmentsRemainingA === 0 && treatmentsRemainingB > 0) { return 1; }
            if (treatmentsRemainingB === 0 && treatmentsRemainingA > 0) { return -1; }
            if (treatmentsRemainingA === 1 && treatmentsRemainingB > 1) { return -1; }
            if (treatmentsRemainingB === 1 && treatmentsRemainingA > 1) { return 1; }

            return a.treatmentsCompleted - b.treatmentsCompleted;
        });
    }

    private filteredPatients(patients: Patient[], upiSearch: string): Patient[] {
        if (upiSearch === "") {
            return patients;
        }
        return patients.filter((patient: Patient) => {
            return patient.upi.toLowerCase().indexOf(upiSearch.toLowerCase()) !== -1;
        });
    }

    private renderAddPatientBox(): JSX.Element {
        return (
            <button
                onClick={this.handleAddNewPatient}
                className="home-screen__add-patient-box"
            >
                <Body size={5}>{Strings.t("addNewPatient")}</Body>
            </button>
        );
    }

    private renderPatientSearch(): JSX.Element {
        return (
            <FlexContainer column className="home-screen__patient-search-container">
                <Body size={4} className="home-screen__all-patients-label">
                    {Strings.t("allPatients") + " (" + this.state.patients.length + ")"}
                </Body>
                <Input
                    onChange={(event: any, {value}: {value: string}): void => this.setState({searchQuery: value})}
                    placeholder={Strings.t("patientSearchHint")}
                    icon="search"
                    iconPosition="left"
                    size="big"
                    value={this.state.searchQuery}
                />
            </FlexContainer>
        );
    }

    private renderSolaExpiration(patient: Patient): JSX.Element|null {
        const TreatmentsUnavailable = (
            <FlexContainer>
                <Label circular size="big" className="home-screen__circle-label">
                    <Icon name="hourglass outline" inverted className="home-screen__hourglass-icon" />
                </Label>
                <Body>{Strings.t("treatmentNotAvailable")}</Body>
            </FlexContainer>
        );

        // Get most recent treatment package
        const treatmentPackage = patient.getLatestTreatmentPackage();

        // If there are no treatment packages at all, display the unavailable message
        if (treatmentPackage === null) {
            return TreatmentsUnavailable;
        }

        // If the current treatment package expiration date is the default (unset), then don't display any expiration
        // (This occurs when patient hasn't completed any treatments in the package yet -- the device only sets the
        // expiration date after the 1st treatment is over)
        if (moment(treatmentPackage.endDateTime).isSame(DEFAULT_TREATMENT_EXPIRATION_DATE)) {
            return null;
        }

        // If the current treatment package expired, display the unavailable message
        if (moment(treatmentPackage.endDateTime).isBefore(moment())) {
            return TreatmentsUnavailable;
        }

        // Otherwise, display the expiration date of the current treatment package
        return (
            <FlexContainer column>
                <Body className="home-screen__treatment-expiration-label">
                    {Strings.t("promiseExpiresOn")}
                </Body>
                <Body bold>{moment(treatmentPackage.endDateTime).format("MMMM Do, YYYY")}</Body>
            </FlexContainer>
        );
    }

    private renderTreatmentsCount(patient: Patient): JSX.Element|null {
        // By default, display the total number of treatments completed (across all packages)
        let message = Strings.t("treatmentsCompletedAcrossAllPackages");
        let icon = (
            <Label circular size="big" className="home-screen__circle-label">
                {patient.treatmentsCompleted}
            </Label>
        );

        const treatmentPackage = patient.getLatestTreatmentPackage();

        // If the most recent treatment package is complete, display the completion message
        if (treatmentPackage !== null && treatmentPackage.isCompleted) {
            icon = (
                <Label circular size="big" className="home-screen__circle-label">
                    <Icon name="flag checkered" className="home-screen__flag-icon" />
                </Label>
            );
            message = Strings.t("standardTreatmentCompleted");
        }

        return (
            <FlexContainer alignment="center">
                {icon}
                <Body size={1}>{message}</Body>
            </FlexContainer>
        );
    }

    private renderPatientItem = (patient: Patient): JSX.Element => {
        return (
            <button
                onClick={(): void => this.handlePatientClicked(patient)}
                className="home-screen__patient-list-item"
                key={patient.id}
            >
                <FlexContainer
                    justification="space-between"
                    alignment="center"
                >
                    <div className="home-screen__patient-upi-column">
                        <Body size={2} bold>{titleCase(patient.upi)}</Body>
                    </div>
                    <div className="home-screen__patient-sola-expiration-column">
                        {this.renderSolaExpiration(patient)}
                    </div>
                    <div className="home-screen__patient-treatments-column">
                        {this.renderTreatmentsCount(patient)}
                    </div>
                </FlexContainer>
            </button>
        );
    };

    private renderNoPatientsFound = (): JSX.Element => {
        return (
            <div>
                <Body size={3} bold>{Strings.t("noPatientsFound")}</Body>
            </div>
        );
    };

    private renderPatientList(): JSX.Element {
        const patients = this.sortedPatients(this.filteredPatients(this.state.patients, this.state.searchQuery));

        if (patients.length === 0 && !this.state.loading) {
            return this.renderNoPatientsFound();
        }

        return (
            <div className="home-screen__patient-list-container">
                <div className="home-screen__patient-list-scroll">
                    {patients.map(this.renderPatientItem)}
                </div>
            </div>
        );
    }

    public renderScreen(): JSX.Element {
        return (
            <FlexContainer column alignment="center" className="home-screen__screen-container">
                <ToastContainer
                    hideProgressBar
                    position="top-center"
                    transition={Slide}
                    draggable={false}
                    className="home-screen__notification-container"
                    toastClassName="home-screen__notification-toast"
                />
                <Loader active={this.state.loading} />
                {this.renderAddPatientBox()}
                {this.renderPatientSearch()}
                {this.renderPatientList()}
            </FlexContainer>
        );
    }
}
