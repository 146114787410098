import * as React from "react";

/**
 * @description Maps font size preset to pixel height
 */
const FontSizePreset = {
    0: 10,
    1: 14,
    2: 18,
    3: 22,
    4: 28,
    5: 36,
    6: 64,
    7: 128,
    8: 256,
};

const gray300 = "#474A4F";

interface IBodyProps {
    size?: number;
    bold?: boolean;
    underline?: boolean;
    color?: string;
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
    href?: string;
    children?: string;
}

const Body: React.FunctionComponent<IBodyProps> = (props: IBodyProps): JSX.Element => {
    const {color, size, bold, underline, className, onClick, href, style} = props;

    const isClickable = onClick !== undefined || href !== undefined;
    const fontSize = FontSizePreset[size || 1];

    // Compute styles based on props
    const textStyle: React.CSSProperties = {
        fontSize,
        lineHeight: fontSize + 4 + "px",
        fontWeight: bold ? "bold" : "normal",
    };

    // Restyle whether text is clickable or not
    if (isClickable) {
        Object.assign(textStyle, {
            cursor: "pointer",
        });
    } else {
        Object.assign(textStyle, {
            color,
            textDecoration: underline ? "underline" : "none",
        });
    }

    // Override computed styles with passed-in styles
    Object.assign(textStyle, style);

    return React.createElement(
        isClickable ? "a" : "span",
        {onClick, href, style: textStyle, className},
        props.children,
    );
};

Body.defaultProps = {
    size: 1,
    bold: false,
    underline: false,
    color: gray300,
    style: {},
};

export default Body;
