import en from "./en";

const languageSelection = {
    English: "en",
};
Object.freeze(languageSelection);

const selectedLanguage = languageSelection.English;
const translations = {
    en,
};

export default class Strings {
    public static t(lookup: keyof typeof en): string {
        let translatedString = translations[selectedLanguage][lookup];
        if (translatedString === undefined) {
            translatedString = "missing \"_\" translation".replace("_", selectedLanguage + "." + lookup);
            return translatedString;
        }
        return translatedString;
    }
}
