/**
 *  Documents and provides consistency for accessing key value pair stores.
 */

const StorageKeys = {
    sessionUsername: "username",
    sessionPassword: "password",

};

export default StorageKeys;
