export class BaseUroShapeError extends Error {
    constructor(message?: string) {
        super();
        this.name = this.constructor.name;
        if (message) {
            this.message = message;
        }
    }
}

export class NotLoggedInError extends BaseUroShapeError {}

export class PaymentProviderError extends BaseUroShapeError {}
