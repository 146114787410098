import moment from "moment";
import UUID from "uuid/v4";
import Regex from "../defs/Regex";

const DEFAULT_TREATMENT_PACKAGE_SIZE = 18;

export const DEFAULT_TREATMENT_EXPIRATION_DATE = moment.utc("2001-01-01T00:01:00.000Z");

export interface ICloudPatient {
    age: number;
    clientId: string;
    consent: boolean;
    diagnosisList: string[];
    gender: string;
    hasHadMassage: boolean;
    hasHadTherapy: boolean;
    id: string;
    lastName: string;
    maxTreatments: number;
    packages: ITreatmentPackage[];
    packagesCompleted: number;
    phoneNumber: string;
    providerName: string;
    providerNPI: string;
    recognized: boolean;
    symptoms: ISymptom[];
    treatmentLocation: string;
    treatments: ITreatment[];
    treatmentsCompleted: number;
    upi: string;
}

export interface INewPatient {
    lastName: string;
    upi: string;
    phoneNumber: string;
    providerName: string;
    providerNPI: string;
    consent: boolean;
}

export interface ITreatmentPackage {
    endDateTime: string;
    isCompleted: boolean;
    maxTreatments: number;
    packageNumber: number;
    patientId: string;
}

export interface ITreatment {
    durationSecs: number;
    endDateTime: string;
    isCompleted: boolean;
    packageNumber: number;
    patientId: string;
    powerW: number;
    startDateTime: string;
    TreatmentNumber: number;
    vaginalLengthC: number;
}

interface ISymptom {
    condition: string;
    level: number;
    packageNumber: number;
    patientId: string;
    questionnaireAnswer: string;
    scaleType: string;
    skipped: boolean;
    treatmentNumber: number;
    yearsAffected: number;
}

export default class Patient {
    public age: number = -1;
    public clientId: string = "";
    public consent: boolean = false;
    public diagnosisList: string[] = [];
    public gender: string = "Unspecified";
    public hasHadMassage: boolean = false;
    public hasHadTherapy: boolean = false;
    public id: string = "";
    public lastName: string = "";
    public maxTreatments: number = 0;
    public packages: ITreatmentPackage[] = [];
    public packagesCompleted: number = 0;
    public phoneNumber: string = "";
    public providerName: string = "";
    public providerNPI: string = "";
    public recognized: boolean = true;
    public symptoms: ISymptom[] = [];
    public treatmentLocation: string = "Unspecified";
    public treatments: ITreatment[] = [];
    public treatmentsCompleted: number = 0;
    public upi: string = "";

    public static FromCloud(json: ICloudPatient): Patient {
        return new Patient()._constructorFromJSON(json);
    }

    public static FromScratch(patient: INewPatient): Patient {
        return new Patient()._constructorFromScratch(patient);
    }

    public static FromPatient(patient: Patient): Patient {
        return new Patient()._constructFromCopy(patient);
    }

    private _constructorFromScratch(patient: INewPatient): Patient {
        this.id = UUID();
        this.lastName = Patient.trimFields(patient.lastName);
        this.phoneNumber = Patient.trimFields(patient.phoneNumber);
        this.providerName = Patient.trimFields(patient.providerName);
        this.providerNPI = Patient.trimFields(patient.providerNPI);
        this.upi = Patient.generateUPI(patient.lastName, patient.phoneNumber);
        this.consent = patient.consent;

        return this;
    }

    private _constructorFromJSON(patient: ICloudPatient): Patient {
        this.age = patient.age;
        this.clientId = patient.clientId === undefined ? "" : patient.clientId;
        this.consent = patient.consent;
        this.diagnosisList = patient.diagnosisList;
        this.gender = patient.gender;
        this.hasHadMassage = patient.hasHadMassage;
        this.hasHadTherapy = patient.hasHadTherapy;
        this.id = patient.id;
        this.lastName = patient.lastName;
        this.maxTreatments = patient.maxTreatments;
        this.packages = patient.packages === undefined ? [] : patient.packages;
        this.packagesCompleted = patient.packagesCompleted;
        this.phoneNumber = patient.phoneNumber;
        this.providerName = patient.providerName;
        this.providerNPI = patient.providerNPI;
        this.recognized = patient.recognized;
        this.symptoms = patient.symptoms;
        this.treatmentLocation = patient.treatmentLocation;
        this.treatments = patient.treatments;
        this.treatmentsCompleted = patient.treatmentsCompleted;
        this.upi = patient.upi;

        return this;
    }

    private _constructFromCopy(patient: Patient): Patient {
        this.age = patient.age;
        this.clientId = patient.clientId;
        this.consent = patient.consent;
        this.diagnosisList = patient.diagnosisList;
        this.gender = patient.gender;
        this.hasHadMassage = patient.hasHadMassage;
        this.hasHadTherapy = patient.hasHadTherapy;
        this.id = patient.id;
        this.lastName = patient.lastName;
        this.maxTreatments = patient.maxTreatments;
        this.packages = patient.packages;
        this.packagesCompleted = patient.packagesCompleted;
        this.phoneNumber = patient.phoneNumber;
        this.providerName = patient.providerName;
        this.providerNPI = patient.providerNPI;
        this.recognized = patient.recognized;
        this.symptoms = patient.symptoms;
        this.treatmentLocation = patient.treatmentLocation;
        this.treatments = patient.treatments;
        this.treatmentsCompleted = patient.treatmentsCompleted;
        this.upi = patient.upi;

        return this;
    }

    public getLatestTreatmentPackage(): ITreatmentPackage|null {
        if (this.packages.length === 0) {
            return null;
        }
        return this.packages[this.packages.length - 1];
    }

    public get treatmentsRemaining(): number {
        return this.maxTreatments - this.treatmentsCompleted;
    }

    public addTreatmentPackage(): void {
        // Mark previous package (if any) as completed
        if (this.packages.length !== 0) {
            this.packages[this.packages.length - 1].isCompleted = true;
        }
        // Auto-increment package number based on current packages
        let packageNumber = 0;
        if (this.packages.length > 0) {
            packageNumber = Math.max(...this.packages.map((pkg: ITreatmentPackage) => pkg.packageNumber)) + 1;
        }

        this.packages.push({
            endDateTime: DEFAULT_TREATMENT_EXPIRATION_DATE.toISOString(),
            isCompleted: false,
            maxTreatments: DEFAULT_TREATMENT_PACKAGE_SIZE,
            packageNumber,
            patientId: this.id,
        });

        this.maxTreatments += DEFAULT_TREATMENT_PACKAGE_SIZE;
    }

    public static generateUPI = (lastName: string, phoneNumber: string): string => {
        const formattedLastName = Patient.trimFields(lastName).toLowerCase();
        const formattedPhoneNumber = phoneNumber.trim().replace(Regex.phoneNumberSymbols, "");
        return formattedLastName + formattedPhoneNumber;
    };

    private static trimFields(input: string): string {
        return input.trim().replace(Regex.whiteSpace, " ");
    }
}
