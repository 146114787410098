import moment, { Moment } from "moment";
import Cloud from "../defs/Cloud";
import { PaymentProviderError } from "../defs/Errors";
import { IAddClientResponse, IAddOrderResponse, IApiToken, IError, IOscToken } from "../defs/PaymentAPI";

const PROXY_LOOKUP = "PIC";
const PRODUCT_CODE = 2001;
const PRODUCT_QUANTITY = 1;
const NETWORK_DELAY_SECS = 60 * 5; // arbitrarily decided on 5 minutes to account for network latency + clock drift

export default class PaymentManager {
    private static savedToken: string|null = null;
    private static tokenExpiration: Moment|null = null;

    /**
     * Requests an API token from the payment provider to authenticate future requests
     * If an unexpired token has already been saved, skips the request and returns that token instead
     * @param ignoreSaved - Whether to ignore the previously saved token and force a new token request
     */
    public static async getApiToken(ignoreSaved: boolean = false): Promise<string> {
        // Try to hit the cache before requesting a fresh token
        if (
            !ignoreSaved
            && PaymentManager.savedToken !== null
            && PaymentManager.tokenExpiration !== null
            && PaymentManager.tokenExpiration.isAfter(moment())
        ) {
            return PaymentManager.savedToken;
        }

        const response = await Cloud.doProxyRequest(
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            },
            PROXY_LOOKUP,
            "get_token",
            {
                // CLIENT_ID and CLIENT_SECRET are ProxyVariables set in Parlay Cloud
                client_id: "${CLIENT_ID}",
                client_secret: "${CLIENT_SECRET}",
                grant_type: "client_credentials",
            },
        );

        const content = await response.json() as IApiToken|IError;
        if ("error" in content) {
            throw new PaymentProviderError(content.error);
        }

        PaymentManager.savedToken = content.access_token;
        PaymentManager.tokenExpiration = moment().add(
            // Subtract estimated network delay from token lifetime
            Math.max(content.expires_in - NETWORK_DELAY_SECS, 0),
            "seconds",
        );

        return content.access_token;
    }

    /**
     * Requests the URL to PIC's Online Service Center website
     */
    public static async getPaymentPortalUrl(): Promise<string> {
        const token = await PaymentManager.getApiToken();

        const response = await Cloud.doProxyRequest(
            {
                method: "GET",
                headers: {
                    "X-Proxy-Authorization": "Bearer " + token,
                },
            },
            PROXY_LOOKUP,
            "Customer/OscToken",
        );

        if (!response.ok) {
            throw new PaymentProviderError(
                "Unable to retrieve payment portal URL" +
                ` (${response.status} ${response.statusText})`,
            );
        }

        const content = await response.json() as IOscToken;
        if (!content.valid || content.error.length) {
            throw new PaymentProviderError("Unable to retrieve payment portal URL");
        }

        return content.properties.loginUrl;
    }

    public static async addSalesOrder(price: string, clientId: string): Promise<IAddOrderResponse> {
        const token = await PaymentManager.getApiToken();

        const response = await Cloud.doProxyRequest(
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-Proxy-Authorization": "Bearer " + token,
                },
            },
            PROXY_LOOKUP,
            "SalesOrder/add_order",
            {
                Order: {
                    client: clientId,
                },
                Items: [
                    {
                        product: PRODUCT_CODE,
                        qty: PRODUCT_QUANTITY,
                        promptAnswers: {
                            Prompt1: {
                                value: price,
                                colorvalue: "",
                            },
                        },
                    },
                ],
            },
        );

        const content = await response.json() as IAddOrderResponse;

        if (!content.valid || content.error.length) {
            throw new PaymentProviderError("Unable to add sales order");
        }
        return content;
    }

    /**
     * Create a client (i.e. Patient) via the payment provider
     */
    public static async addClient(lastName: string, phoneNumber: string): Promise<IAddClientResponse> {
        const token = await PaymentManager.getApiToken();

        const response = await Cloud.doProxyRequest(
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Proxy-Authorization": "Bearer " + token,
                },
            },
            PROXY_LOOKUP,
            "Client",
            {
                Client: {
                    Name: lastName,
                    FirstName: "",
                    Phone: phoneNumber,
                },
            },
        );

        const content = await response.json() as IAddClientResponse;

        if (!content.valid || content.error.length) {
            throw new PaymentProviderError("Unable to add client");
        }
        return content;
    }

    private static async addQuote(price: string, clientId: string): Promise<IAddOrderResponse> {
        const token = await PaymentManager.getApiToken();

        const response = await Cloud.doProxyRequest(
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-Proxy-Authorization": "Bearer " + token,
                },
            },
            PROXY_LOOKUP,
            "SalesOrder/create_quote",
            {
                Order: {
                    client: clientId,
                },
                Items: [
                    {
                        product: PRODUCT_CODE,
                        qty: PRODUCT_QUANTITY,
                        promptAnswers: {
                            Prompt1: {
                                value: price,
                                colorvalue: "",
                            },
                        },
                    },
                ],
            },
        );

        const content = await response.json() as IAddOrderResponse;

        if (!content.valid || content.error.length) {
            throw new PaymentProviderError("Unable to add quote");
        }
        return content;
    }

    private static async deleteQuote(orderId: string, paymentToken?: string): Promise<void> {
        // This is intentionally ran in a Promise and not awaited in order to not block UI. For now, we don't care
        // whether or not this actually succeeds and don't need to handle it.
        await Cloud.doProxyRequest(
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "X-Proxy-Authorization": "Bearer " + paymentToken,
                },
            },
            PROXY_LOOKUP,
            "SalesOrder/" + orderId + "/void_order",
            {},
        );
    }

    /**
     * Gets a quote from the payment provider and then deletes it
     * @returns Quoted price to UroShape
     */
    public static async getTemporaryQuote(price: string, clientId: string): Promise<string> {
        const token = await PaymentManager.getApiToken();

        const quoteResult = await PaymentManager.addQuote(price, clientId);

        // This is intentionally not awaited in order to not block UI
        // We don't care when this finishes or whether it succeeds
        PaymentManager.deleteQuote(quoteResult.properties.Order.wo, token);

        return quoteResult.properties.Order.total;
    }
}
