/* tslint:disable:max-line-length */

const Regex = {
    ageValidation: /^([0-9]+)$/,
    passwordValidation: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,128}$/,
    phoneNumberSymbols: /[-#+()\/ ]/g,
    phoneNumberValidation: /^([0-9-#+()\/ ]*)$/,
    providerNPIValidation: /^\d+$/,
    whiteSpace: /\s+/g,
    emailValidation: /^\S+@\S+\.\S+$/,
    unsafeChars: /[^\w\s]/g,
};

export default Regex;
