import { AsyncLocalStorage, Cloud as ParlayCloud } from "promenade-js";
import DevMode from "./DevMode";

const CLOUD_URL = DevMode ? "https://dev.parlay.cloud" : "https://prod.parlay.cloud";
// const CLOUD_URL = "http://127.0.0.1:8000"; // uncomment to use offline cloud

export const PROJECT_ID = DevMode ? 4 : 3;

console.log("Cloud URL:", CLOUD_URL);
const Cloud = new ParlayCloud(CLOUD_URL, AsyncLocalStorage);
export default Cloud;
