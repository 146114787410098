import Cloud, { PROJECT_ID } from "../defs/Cloud";
import PaymentManager from "./PaymentManager";

const ROOT_USER_EMAIL = "uroshape@promenadesoftware.com";
const ROOT_PASSWORD_PREFIX = "s0La,root__";
export const USERNAME_PREFIX = "uroshape_";
const PROXY_KEY_CLIENT_ID = "CLIENT_ID";
const PROXY_KEY_CLIENT_SECRET = "CLIENT_SECRET";

class BaseProviderManagerError extends Error {
    public original: any;

    constructor(originalError: any) {
        super();
        this.original = originalError;
        this.message = originalError.message;
        this.name = this.constructor.name;
    }
}
export class RootUserError extends BaseProviderManagerError {}
export class OrganizationUserError extends BaseProviderManagerError {}

export default class ProviderManager {
    /**
     * @summary Generates the ParlayCloud data structures needed to register a new UroShape provider
     *
     * @description
     * 1. Creates a ParlayCloud user to serve as the "root" owner of all provider data. No human will directly login
     * using this account, but it allows future access to be granted to other users as needed. This user's credentials
     * are the client ID and (prefixed) secret given by PIC.
     * 2. Creates a ParlayCloud user to allow provider employees to sign in via a single shared account. This user's
     * credentials are passed in via parameters (from registration form).
     * 3. Creates a ParlayCloud user group to store patient data for the provider.
     * 4. Creates ParlayCloud proxy variables with the PIC client ID and secret for the root user and organization user
     */
    public static async createNewProvider(
        picAccountId: string,
        picUserId: string,
        picPassword: string,
        organizationName: string,
        organizationUsername: string,
        password: string,
        email: string,
    ): Promise<void> {
        await Cloud.logout();

        // Create the root user
        const picClientId = picAccountId + "_" + picUserId;
        const rootUsername = USERNAME_PREFIX + picClientId;
        const rootPassword = ROOT_PASSWORD_PREFIX + picPassword; // Prefix so it will pass password requirements
        const rootUserUuid = await ProviderManager.createRootUser(rootUsername, rootPassword);

        // Authenticate as root user to perform next operations
        await Cloud.login(rootUsername, rootPassword);

        // Create root user's proxy variables for PIC client ID and secret
        await Promise.all([
            Cloud.setProxyVariable({}, PROXY_KEY_CLIENT_ID, picClientId, rootUserUuid),
            Cloud.setProxyVariable({}, PROXY_KEY_CLIENT_SECRET, picPassword, rootUserUuid),
        ]);

        // Test client ID and secret with PIC by trying to get a temporary token
        await PaymentManager.getApiToken(true);

        // Create the organization user
        const orgUsername = USERNAME_PREFIX + organizationUsername;
        const orgUserUuid = await ProviderManager.createOrganizationUser(orgUsername, password, email);

        // Create the user group & add organization user to it
        await ProviderManager.createUserGroupWithUsers(organizationName, orgUserUuid);

        // Authenticate as organization user to perform next operations
        await Cloud.logout();
        await Cloud.login(USERNAME_PREFIX + organizationUsername, password);

        // Create organization user's proxy variables for PIC client ID and secret
        await Promise.all([
            Cloud.setProxyVariable({}, PROXY_KEY_CLIENT_ID, picClientId, orgUserUuid),
            Cloud.setProxyVariable({}, PROXY_KEY_CLIENT_SECRET, picPassword, orgUserUuid),
        ]);
    }

    private static async createRootUser(username: string, password: string): Promise<string> {
        try {
            try {
                // Try to login to skip user creation if user already exists
                const {uuid} = await Cloud.login(username, password);
                await Cloud.logout();
                return uuid;
            } catch (e) {
                return await Cloud.addUser(
                    ROOT_USER_EMAIL,
                    username,
                    password,
                    undefined,
                    PROJECT_ID,
                    undefined,
                    false,
                    undefined,
                    undefined,
                    true,
                );
            }
        } catch (e) {
            // Wrap errors in custom error class
            throw new RootUserError(e);
        }
    }

    private static async createOrganizationUser(username: string, password: string, email: string): Promise<string> {
        try {
            return await Cloud.addUser(
                email,
                username,
                password,
                undefined,
                PROJECT_ID,
                undefined,
                false,
                undefined,
                undefined,
                true,
            );
        } catch (e) {
            // Wrap errors in custom error class
            throw new OrganizationUserError(e);
        }
    }

    private static async createUserGroupWithUsers(name: string, orgUserUuid: string): Promise<void> {
        const userGroup = await Cloud.createUsergroup(name, PROJECT_ID);

        // Give organization user access to user group
        return Cloud.addUsergroupAccess(userGroup.uuid, orgUserUuid, {
            read_path_access_list: ["/"],
            write_path_access_list: ["/"],
            user_add_access: false,
            user_remove_access: false,
        });
    }
}
