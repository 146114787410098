export const SESSION_TIME_MINS = 5;  // time after which to logout the user if no interaction

class SessionManager {
    public expirationHandlers: Array<() => any> = [];
    private timerId: number|undefined = undefined;

    /**
     * Starts a user session
     * Any time the app has no user interaction, a timer will count down until the session expires
     */
    public startSession = (): void => {
        document.addEventListener("mousedown", this.resetTimer);
        document.addEventListener("keydown", this.resetTimer);
        document.addEventListener("wheel", this.resetTimer);
        this.resetTimer();
    };

    /**
     * Gracefully ends a user session
     * The timer is stopped
     */
    public endSession = (): void => {
        document.removeEventListener("mousedown", this.resetTimer);
        document.removeEventListener("keydown", this.resetTimer);
        document.removeEventListener("wheel", this.resetTimer);
        clearTimeout(this.timerId);
        this.timerId = undefined;
    };

    /**
     * Reset the timer to its original starting time and continue counting down
     */
    private resetTimer = (): void => {
        clearTimeout(this.timerId);
        this.timerId = window.setTimeout(this.expireTimer, SESSION_TIME_MINS * 60 * 1000);
    };

    /**
     * Forcefully ends a user session by expiring
     */
    private expireTimer = (): void => {
        this.endSession();
        this.expirationHandlers.forEach((handler: () => any) => handler());
    };

}

export default new SessionManager();
