import { RouteManager } from "promenade-react-js";
import PageNotFoundScreen from "../screens/error/PageNotFoundScreen";
import {
    AddPatientScreen,
    DeviceHelpScreen,
    HomeScreen,
    PatientDetailsScreen,
    PaymentDetailsScreen,
    PaymentFailureScreen,
    PaymentSuccessScreen,
    RegisterProviderScreen,
} from "../screens/hcp";
import { LoginScreen, PasswordResetScreen } from "../screens/login";

export const NEW_TREATMENT: string = "new";
export const ADDITIONAL_TREATMENT: string = "additional";

const ScreenPaths = {
    DEFAULT: LoginScreen.path,
    LOGIN: LoginScreen.pathAlias,
    HOME: HomeScreen.path,
    ADD_PATIENT: AddPatientScreen.path,
    PATIENT_DETAILS: PatientDetailsScreen.path,
    PAYMENT_DETAILS: PaymentDetailsScreen.path,
    PAYMENT_SUCCESS: PaymentSuccessScreen.path,
    PAYMENT_FAILURE: PaymentFailureScreen.path,
    PASSWORD_RESET: PasswordResetScreen.path,
    REGISTER_PROVIDER_SCREEN: RegisterProviderScreen.path,
    DEVICE_HELP: DeviceHelpScreen.path,
};

export function registerScreens(): RouteManager {
    const routeManager = new RouteManager(LoginScreen);

    routeManager.registerRedirect(ScreenPaths.LOGIN, ScreenPaths.DEFAULT);
    routeManager.registerScreen(ScreenPaths.HOME, HomeScreen);
    routeManager.registerScreen(ScreenPaths.ADD_PATIENT, AddPatientScreen);
    routeManager.registerScreen(ScreenPaths.PATIENT_DETAILS, PatientDetailsScreen);
    routeManager.registerScreen(ScreenPaths.PAYMENT_DETAILS, PaymentDetailsScreen);
    routeManager.registerScreen(ScreenPaths.PAYMENT_SUCCESS, PaymentSuccessScreen);
    routeManager.registerScreen(ScreenPaths.PAYMENT_FAILURE, PaymentFailureScreen);
    routeManager.registerScreen(ScreenPaths.PASSWORD_RESET, PasswordResetScreen);
    routeManager.registerScreen(ScreenPaths.REGISTER_PROVIDER_SCREEN, RegisterProviderScreen);
    routeManager.registerScreen(ScreenPaths.DEVICE_HELP, DeviceHelpScreen);
    routeManager.registerPageNotFoundScreen(PageNotFoundScreen);

    return routeManager;
}

export default ScreenPaths;
