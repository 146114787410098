import { CloudLocalCache, CloudLocalCacheEntry} from "promenade-js";

import LoginManager from "../managers/LoginManager";
import Cloud from "./Cloud";
import { NotLoggedInError } from "./Errors";

function EntryAlias(path: string, data: object): CloudLocalCacheEntry {
    const date = new Date();

    if (LoginManager.currentUser === null || LoginManager.currentGroup === null) {
        throw new NotLoggedInError();
    }

    return new CloudLocalCacheEntry(
        path,
        LoginManager.currentGroup,
        data,
        date.toISOString(),
        LoginManager.currentUser.uuid,
    );
}

export { EntryAlias };
export default new CloudLocalCache(Cloud, "UroshapeCloudCache", 30, true, true);
