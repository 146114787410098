import { ICloudUserGroupData } from "promenade-js";
import Cloud from "../defs/Cloud";
import CloudCache, { EntryAlias } from "../defs/CloudCache";
import CloudPaths from "../defs/CloudPaths";
import { NotLoggedInError } from "../defs/Errors";
import Patient, { ICloudPatient } from "../models/Patient";
import LoginManager from "./LoginManager";

type TLazyCallback = ((patient: object) => void)|null;

export default class PatientManager {

    public static async addPatient(patient: Patient): Promise<Patient> {
        await PatientManager.storeUPI(patient.id, patient.upi);
        await PatientManager.storePatients([patient]);
        return patient;
    }

    public static storePatients(patients: [Patient]): Promise<void> {
        const patientEntries = patients.map((patient: Patient) => {
            const path = CloudPaths.PATIENTS + patient.id;
            return EntryAlias(path, patient);
        });

        return CloudCache.saveEntries(patientEntries);
    }

    private static async storeUPI(patientID: string, upi: string): Promise<void> {
        if (LoginManager.currentGroup === null) {
            throw new NotLoggedInError();
        }
        const path = CloudPaths.UPI + upi;
        return Cloud.postUsergroup(LoginManager.currentGroup, path, patientID);
    }

    public static async upiIsInUse(patientID: string, upi: string): Promise<boolean> {
        if (LoginManager.currentGroup === null) {
            throw new NotLoggedInError();
        }
        const path = CloudPaths.UPI + upi + CloudPaths.IS_EXACT;
        const upis = await Cloud.getUsergroup(LoginManager.currentGroup, path);
        if (upis[0] === undefined || typeof upis[0].data !== "string") {
            return false;
        }
        const reservedPatient = JSON.parse(upis[0].data);
        return reservedPatient !== null && reservedPatient !== patientID;
    }

    public static async getPatients(
        lazyCallback: TLazyCallback= null,
        withCloudFetch: boolean= true,
    ): Promise<Patient[]> {
        if (LoginManager.currentGroup === null) {
            throw new NotLoggedInError();
        }
        const patientPromise = CloudCache.query(
            LoginManager.currentGroup,
            CloudPaths.PATIENTS,
            (tableEntries: [ICloudUserGroupData]) => {
                const patients = PatientManager.constructPatientsFromTableEntries(tableEntries);
                if (typeof lazyCallback === "function") {
                    lazyCallback(patients);
                }
            },
            withCloudFetch,
        );

        const patientTableEntries = await patientPromise || [];
        return PatientManager.constructPatientsFromTableEntries(patientTableEntries);
    }

    public static async getPatient(
        patientID: string,
        lazyCallback: TLazyCallback= null,
        withCloudFetch: boolean= true,
    ): Promise<Patient> {
        if (LoginManager.currentGroup === null) {
            throw new NotLoggedInError();
        }
        const patientPromise = CloudCache.query(
            LoginManager.currentGroup,
            CloudPaths.PATIENTS + patientID,
            (tableEntries: [ICloudUserGroupData]) => {
                const patient = Patient.FromCloud(tableEntries[0].data as ICloudPatient);
                if (typeof lazyCallback === "function") {
                    lazyCallback(patient);
                }
            },
            withCloudFetch,
        );

        const patientTableEntries = await patientPromise || [];
        return PatientManager.constructPatientsFromTableEntries(patientTableEntries)[0];
    }

    private static constructPatientsFromTableEntries(tableEntries: [ICloudUserGroupData]): Patient[] {
        const constructedPatients = [];

        for (const {data} of tableEntries) {
            constructedPatients.push(Patient.FromCloud(data as ICloudPatient));
        }
        return constructedPatients;
    }
}
